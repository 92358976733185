import { Button as AntButton, Spin } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import './NewButton.less'
import classNames from 'classnames'
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'
import styles from './Button.module.less'
import classnames from 'classnames'
import {
  ButtonSize,
  ButtonVariant,
} from '@/entities/ui/NewButton/Buttons.constants'

export const NewButton = (props: ButtonProps) => {
  const { className = '', ...rest } = props

  return <AntButton className={classNames('newButton', className)} {...rest} />
}

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: 'text' | 'default' | 'dashed'
  buttonColor?: ButtonVariant
  isLoading?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  size?: ButtonSize
  withBorderRadius?: boolean
}

export const Button = ({
  buttonColor = 'orange',
  children,
  isLoading,
  disabled,
  variant = 'default',
  leftIcon,
  rightIcon,
  withBorderRadius = true,
  size = 'lg',
  ...props
}: Props) => {
  return (
    <button
      {...props}
      disabled={disabled || isLoading}
      data-loading={isLoading ? 'true' : 'false'}
      className={classNames(
        styles.button,
        styles[size],
        styles[buttonColor],
        styles[variant || 'default'],
        { [styles.buttonWithLoading]: isLoading },
        { [styles.borderRadius]: withBorderRadius },
        props.className
      )}
    >
      {leftIcon && (
        <span
          data-blured="true"
          className={classNames(styles.leftIcon, styles.bluredElement)}
        >
          {leftIcon}
        </span>
      )}
      {isLoading && (
        <div className={classnames(styles.spinWrapper)}>
          <Spin
            size={variant === 'text' ? 'small' : 'default'}
            className={styles.spinAnimation}
          />
        </div>
      )}
      <span data-blured="true" className={styles.bluredElement}>
        {children}
      </span>

      {rightIcon && (
        <span
          data-blured="true"
          className={classNames(styles.rightIcon, styles.bluredElement)}
        >
          {rightIcon}
        </span>
      )}
    </button>
  )
}
