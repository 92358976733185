import React, { Component } from 'react'

import Modal from '../../../../components/BaseModal'
import './index.less'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import moment from 'moment'
import i18n from '../../../../i18n'
import { momentTzHelper } from '../../../../helpers/momentTz'
import { NewSingleDatePicker } from '../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { ClearIcon, TimeIcon } from '../../../../img/icons'

export class ModalAnchor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.data.id,
      routeId: props.data.routeId,
      name: props.data.name,
      type: props.data.type,
      position: props.data.position + 1,
      address: props.data.address,
      time: props.data.time,
      isTime: true,
      waittime: props.data.waittime,
      isAnchor: props.data.isAnchor,
    }
  }

  onChange = (timeString) => {
    this.setState({
      isTime: true,
    })
    let time
    if (!timeString) {
      time = null
    } else {
      time = timeString.format('h:mm A')
    }

    this.setState({
      time,
    })
  }

  handleChange = (e) => {
    let value = e.target.value
    let waittime = parseInt(e.target.value)
    if (waittime && typeof waittime === 'number') {
      this.setState({
        waittime: value * 60,
      })
    }
  }

  getTimeUtcAnchor = (timeA) => {
    let tz = this.props.data.timeZone
    let time = moment().unix() //время сейчас no UTC
    let localUTCOffset = momentTzHelper()(time * 1000, tz)._offset

    if (localUTCOffset > 0) {
      return moment(timeA, 'h:mm A').add(-localUTCOffset, 'm').format('h:mm A')
    } else {
      return moment(timeA, 'h:mm A')
        .add(Math.abs(localUTCOffset), 'm')
        .format('h:mm A')
    }
  }

  handleAnchorSave = () => {
    let time = this.state.time
    if (!time) {
      this.setState({
        isTime: false,
      })
      return
    }

    time = this.getTimeUtcAnchor(time)

    this.props.saveFn({ ...this.state, isAnchor: 1, time: time })

    this.props.closeFn()
  }

  handleAnchorDel = () => {
    this.props.saveFn({ ...this.state, isAnchor: 0, time: null })
    this.props.closeFn()
  }

  getLocalTime = (timeU) => {
    if (!timeU) {
      return null
    }
    let tz = this.props.data.timeZone
    let time = moment().unix() //время сейчас no UTC
    let localUTCOffset = momentTzHelper()(time * 1000, tz)._offset

    return moment(timeU, 'HH:mm A').add(localUTCOffset, 'm')
  }

  componentDidMount() {
    const time = this.getLocalTime(this.props.data.time)
    this.onChange(time)
  }

  render() {
    let { time, isTime } = this.state

    return (
      <Modal
        title={i18n.t('Edit Anchor(s)')}
        class={'ModalAnchor open'}
        closeFn={this.props.closeFn}
      >
        <div className="anchor-block">
          <div className="anchor-block-header">
            <h3>
              {this.state.position}. {this.state.name}
            </h3>
            <h4>{this.state.type}</h4>
          </div>

          <Row>
            <Col span={24}>
              <span className="LabelText">{i18n.t('Address')}</span>

              <input
                type="text"
                value={this.state.address && this.state.address.address}
                readOnly
              />
            </Col>
          </Row>

          <Row className="Top">
            <Col
              span={12}
              className={time ? 'PaddRight noEmpty' : 'PaddRight timEmpty'}
            >
              <span className="LabelText">{i18n.t('Anchor Time')}</span>

              <NewSingleDatePicker
                style={{ width: '100%' }}
                onChange={(e) => this.onChange(e)}
                className={isTime ? 'anchorDateTime' : 'anchorError anchorDateTime'}
                defaultValue={time && this.getLocalTime(time)}
                use12Hours
                format="h:mm A"
                suffixIcon={<TimeIcon />}
                clearIcon={<ClearIcon />}
                picker="time"
                divider={isTime}
              />
              {!isTime && <span className={'timePickerLine'} />}
            </Col>
            <Col span={12} className="PaddLeft">
              <span className="LabelText">{i18n.t('Wait Time')}</span>
              <input
                onChange={(e) => this.handleChange(e)}
                type="text"
                value={this.state.waittime ? this.state.waittime / 60 : 0}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="anchor-block-footer">
                <button className="btn btn-grey" onClick={this.handleAnchorDel}>
                  {i18n.t('Delete')}
                </button>
                <button
                  className="btn btn-orange"
                  onClick={this.handleAnchorSave}
                >
                  {i18n.t('Save')}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    )
  }
}
