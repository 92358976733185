import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'
import StopChildAddress from './StopChildAddress'
import { ModalAnchor } from '../../../ModalAnchor'
import i18n from '../../../../../../i18n'
import Modal from './../Modal/DeleteStop/index'
import AllPurpose from './../Modal/AllPurpose/index'
import MoreBlock from '../BlockMore'
import ModalAddToTransfer from './ModalAddToTransfer'
import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '../../../../../../components/RepeatFunctions/timeWork'
import anchorAndTimeFN from '../anchorAndTime'
import { sortRidersList } from '../../../../../../components/RepeatFunctions/filter'
import {
  DownIconOutlined,
  RightIcon,
  UpIcon,
} from '../../../../../../img/icons'
import styles from './index.module.less'
import classnames from 'classnames'
import { AttendanceGroup } from '@/pages/Run/components/BuildRun/components/AttendanceGroup/AttendanceGroup'

class Stop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      edit: false,
      showModalAnchor: false,
      pickUp: false,
      dropOff: false,
      anchorItem: '',
      show: false,
      index: props.index3,
      arrive: props.Arrive,
      Wait: props.Wait,
      miles: props.miles,
      min: props.min,
      ridersNamesToTransfer: '',
    }
    this.min = 0
    this.miles = 0
    this.time = ''
    this.obj = {}
    this.showEdit = false
  }

  componentDidMount() {
    this.obj = {
      title: i18n.t('Edit Bus Stop'),

      input: [
        {
          label: i18n.t('Вus Stop Name'),
          placeholder: i18n.t('Name a New Bus Stop'),
        },
      ],

      Address: [
        {
          label: i18n.t('Address'),
          placeholder: 'address',
        },
      ],

      minute: [
        {
          label: i18n.t('Wait Time'),
          placeholder: i18n.t('In Minutes'),
        },
      ],
      stops: [
        {
          label: i18n.t('Enter Stop Number'),
          placeholder: i18n.t('or Stop Will Be Placed Last on the List'),
        },
      ],
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  openModalAnchor = () => {
    this.setState({
      showModalAnchor: true,
    })
  }

  closeModal = () => {
    this.setState({
      showModalAnchor: false,
      removeModal: false,
      editStop: false,
    })
  }

  fn = () => {
    console.log('test')
  }

  editToogle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleChange = (type, e) => {
    e.preventDefault()
    if (type === 'newPosition') {
      //this.props.changePosFn(idElement, newPosition, inStop, child_address_id)
      if (e.target.value) this.props.changePosFn(this.state.id, e.target.value)
    }
    this.setState({
      [type || '1']: e.target.value,
    })
  }

  delChildFromStop = (id, child, address) => {
    this.props.removeChildFromStop(id, child, address, this.state.routeId)
  }

  saveAnchor = (data) => {
    this.props.dispatch(runActions.editAnchor(data))
  }

  handleGetStatus() {
    this.props.routePointChildren.forEach((address) => {
      let type = address.childaddress?.type
      if (type === 1) {
        this.setState({
          pickUp: false,
          dropOff: true,
        })
      } else {
        this.setState({
          pickUp: true,
          dropOff: false,
        })
      }
    })
  }

  removeRun = () => {
    this.setState({
      removeModal: true,
    })
  }

  handleStop = () => {
    this.setState({
      editStop: true,
    })
  }

  handleAnchor = () => {
    return (
      !this.props.isRoleDispatcher &&
      this.setState({
        showModalAnchor: true,
      })
    )
  }

  handleClick = () => {
    this.setState({
      show: !this.state.show,
    })

    if (!this.state.show) {
      this.setState({
        edit: true,
      })
    }
  }

  editToggle = () => {
    let { edit } = this.state

    if (edit) {
      this.setState({
        show: false,
        edit: false,
      })
    } else {
      this.setState({
        edit: true,
      })
    }
  }

  deletePoint = () => {
    this.props.delFn()
    this.closeModal()
  }

  transferModalOpen = (address) => {
    this.setState({
      modalToTransfer: true,
      point: {
        stopId: this.props.id,
        child_address_id: address.child_address_id,
      },
    })
  }

  modalToTransferClose = () => {
    this.setState({
      modalToTransfer: false,
      point: null,
      ridersNamesToTransfer: '',
    })
  }

  getChildrenList = () => {
    let { routePointChildren } = this.state
    let pickUpChild = []
    let dropOffChild = []

    if (!routePointChildren) return false

    routePointChildren.forEach((x) => {
      if (x.childaddress && x.childaddress.type === 1) pickUpChild.push(x)
      if (x.childaddress && x.childaddress.type === 2) dropOffChild.push(x)
    })

    pickUpChild = sortRidersList(pickUpChild)
    dropOffChild = sortRidersList(dropOffChild)
    return { pickUpChild, dropOffChild }
  }

  stopRidersToTransfer = () => {
    const transferridersNames = this.getChildrenList()
      .pickUpChild?.map((item) => item.childaddress?.child?.full_name)
      ?.filter((item) => !!item)
      .join(', ')

    this.setState({
      modalToTransfer: true,
      ridersNamesToTransfer:
        typeof transferridersNames === 'string' && transferridersNames
          ? transferridersNames
          : '',
      point: { stopId: this.props.id, child_address_id: null },
    })
  }

  childList = () => {
    let { id, routePointChildren, object } = this.state
    const { dropOffChild, pickUpChild } = this.getChildrenList()

    return (
      <div className="blockList">
        <div className={styles.attendanceGroupTitleWrapper}>
          {routePointChildren.length > 0 && (
            <div
              className="SchoolAddress"
              style={{ marginTop: 0 }}
              onClick={(e) => this.handleClick(e, 'Ediable')}
            >
              <span className="riders">
                {i18n.t('Riders')} {routePointChildren.length}
              </span>
              {this.state.show ? <UpIcon /> : <DownIconOutlined />}
            </div>
          )}
          <AttendanceGroup
            className={styles.attendanceGroup}
            routePointRoute={this.state.routePointRoute}
          />
        </div>
        {this.state.show && (
          <div className="PuckUpMain marginTop">
            <div className="pickUpBlock">
              {pickUpChild.length > 0 && (
                <div className="pickUp">{i18n.t('Pick Up Riders')} </div>
              )}
              {pickUpChild.map((address, index) => {
                let child = object.find(
                  (child) => child.id === address.childaddress.child_id
                )
                return (
                  <StopChildAddress
                    key={index}
                    id={id}
                    child={child}
                    address={address}
                    delChildFromStop={() =>
                      this.delChildFromStop(id, child, address)
                    }
                    transferMode={true}
                    transferModalOpen={() => this.transferModalOpen(address)}
                    isWriteRoutes={this.props.isWriteRoutes}
                  />
                )
              })}
            </div>

            <div
              className={`dropOffBlock ${
                pickUpChild.length > 0 ? `marginTop` : ''
              }`}
            >
              {dropOffChild.length > 0 && (
                <div className="dropOff">{i18n.t('Drop Off Riders')} </div>
              )}
              {dropOffChild.map((address, index) => {
                let child = object.find(
                  (child) => child.id === address.childaddress.child_id
                )
                return (
                  <StopChildAddress
                    id={id}
                    key={index}
                    child={child}
                    address={address}
                    transferMode={false}
                    delChildFromStop={() =>
                      this.delChildFromStop(id, child, address)
                    }
                    transferModalOpen={() => this.transferModalOpen(address)}
                    isRoleDispatcher={this.props.isRoleDispatcher}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    let {
      id,
      address,
      busStopTitle,
      routeId,
      routePointChildren,
      routePointRoute,
      object,

      edit,
      lastIndex,
    } = this.state
    let children = object
    let { school, routeWaittime, pointOsrmLeg, route } = this.props

    let newPosition = routePointRoute.view_position

    if (!this.showEdit) {
      this.showEdit = true
    }

    let arriveTime = null
    let distance = null
    let duration = null
    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)
      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }

    const isError = !address || !address?.lon || !address?.lat

    const { pickUpChild } = this.getChildrenList()
    const showTransfer = pickUpChild?.length > 0
    return (
      <div className="ListDiv">
        <div
          data-testid="stopPoint"
          key={id}
          className={classnames('busStopPoint bgTranscent Sir', {
            [styles.errorAddress]: isError,
          })}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="buttonTrigger"
            onClick={(e) => this.editToggle(e)}
          >
            <RightIcon
              style={{ color: 'white' }}
              className={classnames(styles.arrow, {
                [styles.active]: edit,
              })}
            />
          </div>
          {!this.props.isWriteRoutes && (
            <MoreBlock
              isLast={newPosition === lastIndex}
              routePointRoute={routePointRoute}
              id={id}
              handleAnchor={this.handleAnchor}
              handleEditStop={this.handleStop}
              removeRun={this.removeRun}
              type={2}
              showTransfer={showTransfer}
              route={route}
              stopRidersToTransfer={this.stopRidersToTransfer}
            />
          )}
          <div className="Head">
            <div className="big">
              {newPosition}. {busStopTitle}{' '}
              {routePointChildren.length > 0 &&
                `(` + routePointChildren.length + ' Riders)'}
            </div>
            {distance && duration && (
              <div className="flex normal distance">
                <span>{distance}</span>
                <span>{duration}</span>
              </div>
            )}
          </div>
          {anchorAndTimeFN.bind(this)()}
          {this.state.edit && !isError && (
            <div className="firstLine">
              <div className="flex">
                <div>
                  <span className="GrayColor">{i18n.t('Address')}</span>
                  <b className="AddressLatLon">
                    {address?.address
                      ? address?.address
                      : address?.lat + ' ' + address?.lon}
                  </b>
                </div>
              </div>
            </div>
          )}
          <div className="firstLine">
            <div style={{ width: '100%' }}>{this.childList()}</div>
          </div>
        </div>

        {this.state.removeModal && (
          <Modal
            closeFn={this.closeModal}
            title={'Delete Stop'}
            deleteBtn={'Delete Stop'}
            deleteObject={this.state.busStopTitle}
            deleteFn={() =>
              this.deletePoint(
                id,
                this.state.position,
                children.id,
                this.state.address.id,
                this.state?.type ?? 1
              )
            }
          />
        )}
        {this.state.editStop && (
          <AllPurpose
            id={id}
            title={i18n.t('Edit Stop Point')}
            positon={newPosition}
            waittime={routeWaittime}
            routeId={routeId}
            busStopTitle={busStopTitle && busStopTitle}
            fullAdress={address.address}
            Address={this.obj.Address}
            input={this.obj.input}
            school={school}
            select={this.obj.select}
            minute={this.obj.minute}
            stops={this.obj.stops}
            closeFn={this.closeModal}
          />
        )}
        {this.state.showModalAnchor && (
          <ModalAnchor
            data={{
              id: id,
              routeId: routePointRoute['route_id'],
              name: busStopTitle,
              type: i18n.t('Bus Stop'),
              position: newPosition,
              address: address,
              time: routePointRoute.time,
              waittime: routePointRoute.waittime,
              isAnchor: routePointRoute.is_anchor,
              routeTime: routePointRoute.routeTime,
              timeZone: this.props.timeZone,
            }}
            saveFn={this.saveAnchor}
            closeFn={this.closeModal}
          />
        )}
        {this.state.modalToTransfer && (
          <ModalAddToTransfer
            closeFn={this.modalToTransferClose}
            point={this.state.point}
            ridersNamesToTransfer={this.state.ridersNamesToTransfer}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    miles: state.routesView.Miles,
    Wait: state.routesView.Wait,
    Arrive: state.routesView.Arrive,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stop)
