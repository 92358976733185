export const DriversIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M7.5 0a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm0 9.375c4.144 0 7.5 1.678 7.5 3.75V15H0v-1.875c0-2.072 3.356-3.75 7.5-3.75z"
      />
    </svg>
  )
}
