export const StudentCap = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="13"
      viewBox="0 0 18 13"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M13.86 6.103L14 8.57c.02.36-.193.693-.64 1-.449.308-1.06.551-1.837.73-.776.18-1.617.27-2.523.27-.906 0-1.747-.09-2.523-.27-.776-.179-1.388-.422-1.836-.73-.448-.307-.662-.64-.641-1l.14-2.468 4.485 1.414c.115.036.24.054.375.054s.26-.018.375-.054l4.484-1.414zm3.968-2.774c.115.042.172.123.172.242 0 .12-.057.2-.172.243l-8.75 2.75A.329.329 0 0 1 9 6.57a.329.329 0 0 1-.078-.007l-5.094-1.61c-.224.177-.409.468-.555.871a4.971 4.971 0 0 0-.265 1.395.919.919 0 0 1 .492.851c0 .36-.151.638-.453.836L3.5 12.29a.23.23 0 0 1-.063.195.23.23 0 0 1-.187.086h-1.5a.23.23 0 0 1-.188-.086.23.23 0 0 1-.062-.195l.453-3.383a.944.944 0 0 1-.453-.836c0-.38.17-.669.508-.867.057-1.078.312-1.937.765-2.578L.172 3.814C.057 3.772 0 3.69 0 3.57s.057-.2.172-.242L8.922.58A.329.329 0 0 1 9 .571c.031 0 .057.003.078.008l8.75 2.75z"
      />
    </svg>
  )
}
