import React, { Component } from 'react'
import { connect } from 'react-redux'
import './index.less'

import Filter from './components/Filter'

import * as runActions from '../../redux/routesView/actions'
import {
  addressInStop,
  addRidersInRun,
  childrenInStop,
  childrenInStopV2,
  stopsListToRun,
} from '../../redux/routesView/actions'
import * as childrenActions from '../../redux/children/actions'

import _ from 'lodash'

import MapContainer from './components/MapContainer/index'
import UnassignedRiders from './components/UnassignedRiders/index'
import BuildRun from './components/BuildRun/BuildRunWrap'
import ErrorBoundary from '../../components/ErrorBoundary'
import { get } from '../Zones/components/crud'
import L from 'leaflet'
import FilterContext, { Context } from './components/Filter/FilterContext'
import ErrorModalCapacity from './components/UnassignedRiders/components/RiderAddress/ErrorModalCapacity'
import capacityCheck from './components/UnassignedRiders/components/RiderAddress/capacityCheck'
import ErrorModalDistance from './helpers/ErrorModalDistance'
import ErrorModal from './components/UnassignedRiders/components/RiderAddress/ErrorModal'
import InactiveRunModal from './components/UnassignedRiders/components/InactiveRunModal'
import { addressWithOptions } from '../../helpers/url'
import { browserHistory } from 'react-router'

class TripBuilder extends Component {
  constructor(props) {
    super(props)
    let activeRuns = [0, 0]
    let ids = props.params
    if (ids.single) {
      activeRuns[0] = +ids.single
    }
    if (ids.run1 && ids.run2 && ids.run1) {
      activeRuns[0] = +ids.run1
      if (ids.run1 !== ids.run2) {
        activeRuns[1] = +ids.run2
      } else {
        browserHistory.push(`/client/RouteTool/routes/${activeRuns[0]}`)
      }
    }

    this.state = {
      activeSeasonId: props.activeSeasonId,
      buildRun: props.buildRun,
      modalAdd: false,
      selectId: _.has(props.location.state, 'id') ? props.location.state.id : 0,
      activeRuns: activeRuns,
      newChild: '',
      modalStop: false,
      modalDepot: false,
      showModalSchool: false,
      clear: false,
      selectedToolElement: [],
      paddingLegend: true,
      zonesList: [],
      addressType: null,
      addressTypeToStop: false,
      runInactive: false,
    }
  }

  componentDidMount() {
    let { dispatch, activeSeasonId } = this.props
    dispatch(runActions.getRoutes(activeSeasonId))
    dispatch(childrenActions.getChildren(activeSeasonId))

    get().then((response) => {
      this.setState({
        zonesList: response,
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.sessionId &&
      nextProps.activeSeason.id &&
      this.state.sessionId !== nextProps.activeSeason.id
    ) {
      this.changeSession(nextProps.activeSeason.id)
    }

    /** RELOAD ACTIVE RUN **/
    let routesList = this.getRoutesListNextProp(nextProps.routes)
    if (
      routesList &&
      routesList.length > 0 &&
      routesList[routesList.length - 1].reloadBuilder
    ) {
      let newActiveRunId = routesList[routesList.length - 1].id
      if (this.state.reloadID === newActiveRunId) return false
      if (this.state.activeRuns[0] === 0) return false
      this.setState({
        reloadID: newActiveRunId,
      })

      this.setActiveRun([newActiveRunId, 0], newActiveRunId)
      browserHistory.push(`/client/RouteTool/routes/${newActiveRunId}`)
      return false
    }
    /** RELOAD ACTIVE RUN END**/

    this.setState({
      buildRun: nextProps.buildRun,
      seasons: nextProps.seasons,
      activeSeason: nextProps.activeSeason,
      sessionId: nextProps.activeSeason.id,
    })
  }

  changeSession = (sessionId) => {
    this.setState({
      activeRuns: [0, 0],
      activeRun: 0,
    })
    this.props.dispatch(runActions.getRoutes(sessionId))
    this.props.dispatch(childrenActions.getChildren(sessionId))
  }

  hoveredAddress = (address_id, is_hovered) => {
    this.setState({
      active_address_hover_id: is_hovered ? address_id : 0,
    })
  }
  //
  getRoutesListNextProp = (routes) => {
    if (!routes) return false
    let all = routes.map((x) => x.routes)
    return _.concat(...all)
  }

  getTypeRun = () => {
    let route

    this.props.routes.forEach((r) => {
      r.routes.forEach((x) => {
        if (x.id === this.state.activeRun) route = x
      })
    })

    return route && route.is_morning
  }

  /*ДОБАВЛЯЕМ ОСТАНОВКУ И ВСЕХ ДЕТЕЙ С ОДНОЙ ТОЧКИ*/
  makeStopInRun = (unassignedAddress) => {
    let riders = []
    let rIds = []
    let { lat, lon } = unassignedAddress.address

    this.props.childrenList.forEach((x) => {
      if (!x.addressesView) return false

      x.addressesView.forEach((y) => {
        if (!y.address) return
        if (
          y.address.lat * 1 === lat * 1 &&
          y.address.lon * 1 === lon * 1 &&
          !y.is_school &&
          !y.is_use
        ) {
          riders.push({
            child_address_id: y.id,
            childId: y.child_id,
          })
          rIds.push(y.id)
        }
      })
    })

    let stopPayload = {
      address: unassignedAddress.address.address,
      is_anchor: 0,
      name: unassignedAddress.address.address,
      children: rIds,
    }
    _.defer(() => this.addInRun('busStop', stopPayload))
    // x.child_address_id, x.childId, data.route_id
    let data = {
      children: riders,
      route_id: this.state.activeRun,
    }
    this.props.dispatch(childrenActions.moveToRun(data))
  }

  /*ДОБАВЛЯЕМ РЕБЕНКА В РАН*/
  addRiderInRun = (addressId, schoolId, childId) => {
    let { activeRun } = this.state
    let { routes } = this.props

    if (!activeRun) {
      this.setState({
        runInactive: true,
      })
      return
    }

    if (!schoolId || !activeRun) {
      return
    }

    let points = []
    routes.forEach((gr) => {
      gr.routes.forEach((r) => {
        if (r.id === activeRun && r.points) {
          points = r.points
        }
      })
    })
    let schoolInRun = points.findIndex(
      (i) => i.type === 3 && i.object.id === schoolId
    ) //добавлена ли школа в поинты?
    let rider = {
      routeId: activeRun,
      childAddressId: addressId,
      childId: childId,
    }
    let schoolPoint = {
      route_id: activeRun,
      school_id: schoolId,
    }
    if (schoolInRun > -1) {
      this.addInRun('rider', rider, false)
    } else {
      this.addInRun('rider', rider, schoolPoint)
    }
    this.deleteFromList(addressId, childId, activeRun)
  }

  /*ДОБАВЛЯЕМ ЛЮБОЕЙ ЭЛЕМЕНТ ПО ТИПУ*/
  addInRun = (type, payload, school, onModalClose) => {
    //добавляю по типу в RUN
    let { dispatch } = this.props
    let activeRun = this.state.activeRun
    let { routeId, childAddressId, address } = payload
    const encodedPlotAddress = encodeURIComponent(address)
    const companyOpts =
      this.props.companyOptions && this.props.companyOptions.geocode_provider
    switch (type) {
      case 'rider': {
        dispatch(runActions.riderInRun({ routeId, childAddressId }, school))
        break
      }
      case 'busStop': {
        dispatch(
          runActions.getAddressGeo(
            addressWithOptions(encodedPlotAddress, companyOpts),
            () => {
              dispatch(
                runActions.busStopInRun({ ...payload, routeId: activeRun })
              )
              onModalClose()
            }
          )
        )
        break
      }
      case 'school': {
        dispatch(runActions.schoolInRun({ ...payload, routeId: activeRun }))
        break
      }
      case 'depot': {
        let hasDepot = this.getDepotPoints(activeRun)

        if (!hasDepot.flag && !payload.position) {
          payload.position = hasDepot.position
        }
        dispatch(runActions.depotInRun({ ...payload, routeId: activeRun }))
        break
      }
      default:
        break
    }
  }

  /*МАССОВО ДОБАВЛЕНИЕ В ОСТАНОВКУ С ПОМОЩЬЮ ФИГУР*/
  addChildrenInStop = (item) => {
    this.props.dispatch(childrenInStop(item))

    this.props.dispatch(childrenActions.moveToRun(item))

    if (this.state.showWarningCapacityShapeRemove) {
      this.state.showWarningCapacityShapeRemove()
    }
    // item.children.forEach(x => {
    //   this.deleteFromList(x.child_address_id, x.child_id, item.route_id);
    // });
  }

  addRidersInStopPoints = (data, removeShape) => {
    let { children, stopPoints, route_id } = data

    let distOver = false
    let arrData = []
    let requestsAjax = []

    let requestData = {
      routeId: route_id,
      childrenPoints: [],
    }

    children.forEach((x) => {
      let bestDist = {}
      let { lat, lng } = x.position
      let pointA = L.latLng(lat, lng)

      stopPoints.forEach(({ lat, lng, id }) => {
        let pointB = L.latLng(lat, lng)
        let dist = pointA.distanceTo(pointB)

        dist = (dist / 1000) * 1.609

        if (!bestDist.dist || bestDist.dist > dist) {
          bestDist = { id: id, dist: dist, riderPoint: x }
        }
      })

      distOver = bestDist.dist > 250
      let data = {
        children: x,
        id: bestDist.id,
        route_id: route_id,
        distOver: distOver,
      }
      requestData.childrenPoints.push({
        pointId: bestDist.id,
        childAddressId: x.child_address_id,
      })
      arrData.push(data)
    })
    if (this.state.addressType) {
      this.setState({
        reqData: requestData,
        addressTypeToStop: true,
      })
    } else {
      requestsAjax.push({
        fn: () => this.props.dispatch(childrenInStopV2(requestData)),
      })
      requestsAjax.push({
        fn: () => this.props.dispatch(childrenActions.moveToRunV2(requestData)),
      })
    }

    if (distOver) {
      this.setState({
        showWarningDistance: true,
        showWarningDistanceData: { r: requestsAjax, shapeDelFn: removeShape },
      })
    } else {
      requestsAjax.forEach((r) => r.fn())
      removeShape()
    }
  }

  showWarningDistanceClose = () => {
    this.setState({
      showWarningDistance: false,
    })
  }

  showWarningDistanceSave = (all) => {
    this.state.showWarningDistanceData.r.forEach((r) => {
      if (all || (!all && !r.distOver)) r.fn()
    })
    this.state.showWarningDistanceData.shapeDelFn()
    this.showWarningDistanceClose()
  }

  /*Моссовое добавление Make a stop - All pins*/
  makeAnyStops = (data) => {
    this.props.dispatch(stopsListToRun(data, this.state.activeRun))

    data.points.forEach((x) => {
      x.children.forEach((riderId, i) => {
        this.deleteFromList(x.unassignedAddress[i], riderId, x.route_id)
      })
    })
  }

  setActiveRun = (activeRuns, activeRun) => {
    if (
      activeRun !== this.state.activeRun ||
      activeRuns !== this.state.activeRuns
    ) {
      this.setState({
        activeRuns: activeRuns,
        activeRun: activeRun,
      })
    }
  }

  getDepotPoints = (activeRun) => {
    let activeR
    this.props.routes.forEach((gr) => {
      gr.routes.forEach((x) => {
        if (x.id === activeRun) {
          activeR = x
        }
      })
    })

    if (activeR) {
      let flag = !!(activeR.points || []).find((x) => x.type === 6)

      return {
        flag: flag,
        position: activeR.is_morning === 1 ? 0 : activeR.points.length,
      }
    }
  }

  deleteFromList = (addressId, childId, activeRun) => {
    this.props.dispatch(
      childrenActions.toggleAddress(childId, addressId, activeRun)
    )
  }

  addRiderInStop = (data) => {
    const { childAddressId, routeId, pointId, childId } = data
    this.props.dispatch(addressInStop({ childAddressId, routeId, pointId }))
    this.deleteFromList(childAddressId, childId, routeId)
  }

  addRidersInRun = (data) => {
    this.props.dispatch(addRidersInRun(data))
    this.props.dispatch(childrenActions.moveToRun(data))

    if (this.state.showWarningCapacityShapeRemove) {
      this.state.showWarningCapacityShapeRemove()
    }
    // data.children.forEach(x => {
    //   this.deleteFromList(x.child_address_id, x.childId, data.route_id);
    // });
  }

  removeStopFromRun = (idStop, position, runId, object) => {
    this.props.dispatch(
      runActions.delPosition(idStop, position, runId, object.routePointRoutes)
    )

    if (!_.isEmpty(object.routePointChildren) && object.type === 3) {
      let childrenList = this.props.childrenList
      object.routePointChildren.forEach((x) => {
        let findChild = (childrenList || []).find((y) => y.id === x.child_id)
        let address = (findChild.addresses || []).find(
          (y) => !y.is_school && y.id === x.childaddress.link_id
        )
        let child_address_id = address.childSchool.child_address_id

        this.deleteFromList(child_address_id, x.child_id, runId)
      })
      return
    }

    if (!_.isEmpty(object.routePointChildren)) {
      object.routePointChildren.forEach((x) => {
        this.deleteFromList(x.child_address_id, x.child_id, runId)
      })
    }

    if (!_.isEmpty(object.routePointTransferChild) && object.type === 5) {
      object.routePointTransferChild.forEach((x) => {
        this.deleteFromList(x.child_address_id, x.childAddress.child_id, runId)
        this.props.dispatch(
          childrenActions.removeChildrenFromTransfer(
            x.childAddress.child_id,
            runId
          )
        )
      })
    }
  }

  removeChildFromTrans = (data, runId) => {
    this.props.dispatch(runActions.delChildFromStop(data, runId))

    this.deleteFromList(data.child_address_id, data.childId, runId)
  }

  removeChildFromStop = (data, runId, item) => {
    this.props.dispatch(runActions.delChildFromStop(data, runId))

    if (item && item.childaddress.is_school) {
      let childrenList = this.props.childrenList
      let findChild = (childrenList || []).find((y) => y.id === data.childId)
      let address = (findChild.addresses || []).find(
        (y) => !y.is_school && y.id === item.childaddress.link_id
      )
      let child_address_id = address.childSchool.child_address_id
      this.deleteFromList(child_address_id, data.childId, runId)
      return
    }

    this.deleteFromList(data.child_address_id, data.childId, runId)
  }

  handleClear = () => {
    this.setState({
      clear: true,
    })
  }

  moveRedirection = (e, marker) => {
    let activeRuns = this.state.activeRuns
    let obj = marker.object

    let secondRunId = 0
    if (obj.type === 5) {
      let secondRun = (obj.routePointRoutes || []).find(
        (x) => x.route_id !== obj.routeId
      )
      if (activeRuns.includes(secondRun.route_id))
        secondRunId = secondRun.route_id
    }

    let newAddress = {
      id: obj.id,
      address: `${e.target._latlng.lat}, ${e.target._latlng.lng}`,
      routeId: obj.routeId,
    }
    this.props.dispatch(
      runActions.editPointsSett(newAddress, secondRunId, true)
    )
  }

  paddingLegend = (bool) => {
    this.setState({
      paddingLegend: bool,
    })
  }

  buildRunMenuOpenType = (type) => {
    this.setState({
      buildRunMenuOpenType: type,
    })
  }

  activeRunTypeCb = (type) => {
    this.setState({
      activeRunTypeCb: type,
    })
  }

  beforeSave = (type, data, shapeRemove) => {
    let { buses, routes } = this.props
    let { activeRun } = this.state
    const addressTypeDiff = data.children.some((child) => {
      if (child.addressType !== this.getTypeRun()) {
        this.setState({
          addressType: child.addressType,
        })
        return true
      }
      return false
    })
    let conf = {
      buses: buses,
      routes: routes,
      activeRun: activeRun,
      addCount: data.children.length,
    }

    let difference = capacityCheck(conf)
    if (difference < 0) {
      this.setState({
        showWarningCapacity: true,
        showWarningCapacityData: data,
        showWarningCapacityType: type,
        showWarningCapacityDifference: difference * -1,
        showWarningCapacityShapeRemove: shapeRemove,
      })
    } else {
      if (type === `addRidersInRun`) {
        if (addressTypeDiff) {
          this.setState({
            showAddressWarningType: type,
            showAddressWarningData: data,
            shapeRemove: shapeRemove,
          })
        } else {
          this.addRidersInRun(data)
          shapeRemove()
        }
      }
      if (type === `addChildrenInStop`) {
        this.addChildrenInStop(data)
      }
      if (type === `addChildrenInStopPoints`) {
        this.addRidersInStopPoints(data, shapeRemove)
      }
    }
  }

  saveAfterWarning = () => {
    let {
      showWarningCapacityData,
      showWarningCapacityType,
      showAddressWarningType,
      showWarningCapacityShapeRemove,
      showAddressWarningData,
      shapeRemove,
      addressTypeToStop,
      reqData,
    } = this.state
    if (showAddressWarningType === `addRidersInRun`) {
      this.addRidersInRun(showAddressWarningData)
      shapeRemove()
    }
    if (showWarningCapacityType === `addRidersInRun`) {
      this.addRidersInRun(showWarningCapacityData)
    }
    if (showWarningCapacityType === `addChildrenInStop`)
      this.addChildrenInStop(showWarningCapacityData)
    if (showWarningCapacityType === `addChildrenInStopPoints`) {
      this.addRidersInStopPoints(
        showWarningCapacityData,
        showWarningCapacityShapeRemove
      )
    }
    if (addressTypeToStop) {
      this.props.dispatch(childrenInStopV2(reqData))
      this.props.dispatch(childrenActions.moveToRunV2(reqData))
    }
    this.capacityWarningClean()
  }

  capacityWarningClean = () => {
    this.setState({
      showWarningCapacity: false,
      showWarningCapacityData: null,
      showWarningCapacityType: null,
      addressTypeToStop: false,
    })
  }

  getMapRefLink = (mapRefObj) => {
    this.setState({
      mapRefLink: mapRefObj,
    })
  }

  saveNewShape = (obj) => {
    let arr = [...this.state.zonesList, obj]

    this.setState({
      zonesList: arr,
    })
  }

  closeErrorModal = () => {
    this.setState({
      addressType: null,
      runInactive: false,
    })
  }

  render() {
    let {
      activeRun,
      activeRuns,
      active_address_hover_id,
      paddingLegend,
      buildRunMenuOpenType,
      zonesList,
      showWarningCapacityDifference,
      showWarningCapacity,
      showWarningDistance,
      mapRefLink,
      addressType,
      runInactive,
    } = this.state
    return (
      <FilterContext map={mapRefLink}>
        <div className="runComponent">
          <div className="runMapComponent unselectable">
            <ErrorBoundary>
              <Context.Consumer>
                {(ct) => {
                  return (
                    <MapContainer
                      moveRedirection={this.moveRedirection}
                      addRidersInRun={this.beforeSave}
                      makeStopInRun={this.makeStopInRun}
                      addressInStop={this.addressInStop}
                      activeRuns={activeRuns}
                      randKey={Math.random()}
                      activeRun={activeRun}
                      paddingLegend={paddingLegend}
                      hoveredAddress={this.hoveredAddress}
                      handleClearElement={this.handleClear}
                      active_address_hover_id={active_address_hover_id}
                      removeStopFromRun={this.removeStopFromRun}
                      removeChildFromStop={this.removeChildFromStop}
                      removeChildFromTrans={this.removeChildFromTrans}
                      addRiderInRun={this.addRiderInRun}
                      makeAnyStops={this.makeAnyStops}
                      setActiveRun={this.setActiveRun}
                      addChildrenInStop={this.beforeSave}
                      deleteFromList={this.deleteFromList}
                      buildRunMenuOpenType={buildRunMenuOpenType}
                      zonesList={zonesList}
                      filters={ct.filtersAllObj}
                      getMapRef={this.getMapRefLink}
                      saveNewShape={this.saveNewShape}
                    />
                  )
                }}
              </Context.Consumer>
            </ErrorBoundary>
          </div>

          <Filter
            filtersArrayCb={this.getChangeInputArray}
            activeRunTypeCb={this.state.activeRunTypeCb}
            activeRunId={activeRun}
            mapRefLink={this.state.mapRefLink}
            zonesList={zonesList}
          />

          <div className="zoneMenu hide" />

          <Context.Consumer>
            {(ct) => {
              return (
                <>
                  {/*<UnassignedRidersV2 />*/}
                  <UnassignedRiders
                    ct={ct}
                    filters={ct.filtersAllObj}
                    filterChange={this.filterChange}
                    addRiderInRun={this.addRiderInRun}
                    addRiderInStop={this.addRiderInStop}
                    activeRun={activeRun}
                    addInRun={this.addInRun}
                    paddingLegend={this.paddingLegend}
                    hoveredAddress={this.hoveredAddress}
                    active_address_hover_id={this.state.active_address_hover_id}
                    zonesList={zonesList}
                  />
                </>
              )
            }}
          </Context.Consumer>

          <BuildRun
            activeRunId={activeRun}
            showAnyModalFn={this.toggle}
            activeRunsIds={activeRuns}
            setActiveRunCb={this.setActiveRun}
            addInRun={this.addInRun}
            removeChildFromStop={this.removeChildFromStop}
            removeStopFromRun={this.removeStopFromRun}
            activeRunTypeCb={this.activeRunTypeCb}
            buildRunMenuOpenTypeCb={this.buildRunMenuOpenType}
          />
        </div>

        {showWarningCapacity && (
          <ErrorModalCapacity
            close={this.capacityWarningClean}
            save={this.saveAfterWarning}
            difference={showWarningCapacityDifference}
          />
        )}

        {showWarningDistance && (
          <ErrorModalDistance
            close={this.showWarningDistanceClose}
            no={() => {
              this.showWarningDistanceSave(false)
            }}
            save={() => {
              this.showWarningDistanceSave(true)
            }}
          />
        )}

        {addressType && (
          <ErrorModal
            close={this.closeErrorModal}
            save={this.saveAfterWarning}
            addressType={addressType}
          />
        )}

        {runInactive && <InactiveRunModal close={this.closeErrorModal} />}
      </FilterContext>
    )
  }
}

function mapStateToProps(state) {
  return {
    routes: state.routesView.routes,
    buildRun: state.buildRun,
    childrenList: state.children.childrenList,
    activeSeason: state.sessions.activeSeason,
    activeSeasonId: state.sessions.activeSeason?.id,
    buses: state.buses,
    companyOptions: state.auth.companyOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripBuilder)
