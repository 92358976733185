import api from '../../apiConfig'
import * as types from './actionTypes'
import { CreatePointRequest, StopPoint } from '@/pages/FieldTrip/Run/models'
import axios from 'axios'
import { localUrlGeo } from '../../configLocal'
import * as commonTypes from '../../common/actionTypes'
import { momentTzHelper } from '../../../helpers/momentTz'

const trip = api.common
const common = api.common
const rq = api.routes
const runV2 = api.runV2

// Get FieldTripRequests

export function getTripAttendance(tripId) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .get(`field-trip/attendance/index?fieldTripId=${tripId}`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data },
            type: types.GET_TRIP,
          })
          dispatch({ type: commonTypes.PENDING_END })
        } else {
          console.warn('error')
        }
      })
  }
}

export function updateAttendance(tripId, data) {
  return function () {
    runV2.put(`field-trip/attendance/update/${tripId}`, data).catch((error) => {
      console.error(error)
    })
  }
}

export function checkUnCheckAllAttendance(tripId, checkedAll) {
  const checked = checkedAll ? 'uncheck-all' : 'check-all'
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    runV2
      .put(`field-trip/attendance/${checked}/${tripId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getTripAttendance(tripId))
          dispatch({
            type: checkedAll ? types.UNCHECK_ALL : types.CHECK_ALL,
          })
          dispatch({ type: commonTypes.PENDING_END })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function changeLock(id, status) {
  return function (dispatch) {
    trip
      .put(
        `field-trips/${id}`,
        JSON.stringify({
          lock: status,
        })
      )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data },

            type: types.GET_STATUS_LOCK,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

export function getRequest(filters) {
  return function (dispatch) {
    trip
      .get(`field-trip-requests?expand=fieldTrip.fieldTripRequests`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data, filters },
            type: types.GET_REQUEST,
          })
        } else {
          console.warn('error')
        }
      })
      .catch((response) => {
        console.log(response)
      })
  }
}

export function getRequestsBuilder(filters) {
  return function (dispatch) {
    trip.get(`field-trip-requests?expand=fieldTrip`).then(function (response) {
      if (response.status === 200) {
        dispatch({
          payload: { data: response.data, filters },
          type: types.GET_REQUESTS_BUILDER,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

// Edit trip
export function editTrip(id, data, comment) {
  return function (dispatch) {
    trip
      .put(`field-trips/${id}`, JSON.stringify(data))
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data },
            type: types.EDIT_TRIP,
            comment: comment,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

// Edit trip
export function reversePoints(id, data, comment) {
  return function (dispatch) {
    trip
      .put(`field-trips/${id}`, JSON.stringify(data))
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data },
            type: types.REVERSE_POINTS,
            comment: comment,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

// Create point
export function createPoint(data = new CreatePointRequest(), tripId, runId) {
  return function (dispatch) {
    trip
      .post(
        `field-trip-points/create-array?id=${data.id}`,
        JSON.stringify(data)
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          dispatch({
            payload: { data: response.data, tripId, runId },
            type: types.CREATE_POINT,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

// Edit point
export function editPoint(data = new StopPoint(), tripId, runId) {
  if (!data.id) return
  return function (dispatch) {
    common
      .put(`field-trip-points/update-array?id=${data.id}`, JSON.stringify(data))
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          dispatch({
            payload: { data: response.data, tripId, runId },
            type: types.EDIT_POINT,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

// Delete point
export function deletePoint(pointId, tripId, runId) {
  if (!pointId) return
  return function (dispatch) {
    trip
      .delete(`field-trip-points/delete-array?id=${pointId}`)
      .then(function (response) {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          dispatch({
            payload: { data: response.data, tripId, runId },
            type: types.DELETE_POINT,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

// Add riders to point
export function addRiders(data = new StopPoint(), tripId, runId) {
  if (!data.id) return
  // delete data.address;
  return function (dispatch) {
    trip
      .put(`field-trip-points/update-array?id=${data.id}`, JSON.stringify(data))
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          dispatch({
            payload: { data: response.data, tripId, runId },
            type: types.ADD_RIDERS,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

// Remove riders from point
export function removeRiders(data = new StopPoint(), tripId, runId) {
  if (!data.id) return
  return function (dispatch) {
    trip
      .put(`field-trip-points/update-array?id=${data.id}`, JSON.stringify(data))
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          dispatch({
            payload: { data: response.data, tripId, runId },
            type: types.REMOVE_RIDERS,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

export function editTripRulesPosint(id, Name, value) {
  let obj = { [Name]: value }
  return function (dispatch) {
    trip
      .put(`field-trip-points/${id}`, JSON.stringify(obj))
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data },
            type: types.EDIT_FIELDTRIP_POINTS,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

export function editTripName(id, Name) {
  let obj = { name: Name }
  return function (dispatch) {
    trip
      .put(`field-trips/${id}`, JSON.stringify(obj))
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data },
            type: types.EDIT_TRIP_NAME,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

// Submit trip
export function submitTrip(id) {
  const headers = { tz: momentTzHelper().guess() }
  return function (dispatch) {
    trip
      .post(`field-trips/submit`, JSON.stringify({ id: id }), { headers })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: { data: response.data },
            type: types.SUBMIT_TRIP,
          })
        } else {
          console.warn('error')
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = err.response.data[0].message

          return dispatch({
            payload: { error: errorMessage },
            type: types.SUBMIT_TRIP,
          })
        }
      })
  }
}

// Inform requestor
export function informRequestor(tripID) {
  return function (dispatch) {
    trip
      .get(`field-trips/inform-requester?id=${tripID}`)
      .then(function (response) {
        if (response.status === 200) {
        } else {
          console.warn('error')
        }
      })
  }
}

// GET DETAILS  (availableApprovals)
export function availableApprovals(tripID) {
  return function (dispatch) {
    trip
      .get(`field-trips/${tripID}?expand=availableApprovals`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            payload: response.data,
            type: types.GET_APPROVALS,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

export function getOSRM(points, trip, run, steps = 'false') {
  return function (dispatch) {
    if (points.length >= 2) {
      points.sort((a, b) => {
        if (a.position > b.position) {
          return 1
        }
        if (a.position < b.position) {
          return -1
        }
        return 0
      })
      points = points.map((point) => {
        if (point.address) {
          if (!point.address.lat) return
          return {
            lat: point.address.lat,
            lon: point.address.lon,
            waittime: point.waittime,
            is_anchor: point.is_anchor,
            time: point.time,
          }
        }
      })

      let send = { points: points, steps: steps }

      axios
        .post(localUrlGeo, send)
        .then(function (response) {
          if (response.status === 200) {
            dispatch({
              type: types.OSRM,
              payload: response.data,
              find: [trip, run],
            })
          } else {
            console.warn('error')
          }
        })
        .catch(() => {
          dispatch({
            type: types.OSRM,
            payload: `ERROR`,
            find: [trip, run],
          })
        })
    }
  }
}
