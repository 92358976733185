import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import axios from 'axios'
import { FormType } from '@/pages/Sessions/components/modals/AddNewSessionModal/AddNewSessionModal.types'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import { notification } from 'antd'
import * as types from '@/redux/sessions/actionTypes'
import { useModalStore } from '@/store/modal/ModalStore'
import { currentSession } from '@/redux/sessions/actions'
import api from '@/redux/apiConfig'
import { ISession } from '@/store/session/sessions.types'
import { createSelector } from 'reselect'
import { useNextYearStore } from '@/pages/Sessions/components/cells/NewYearSessionCell'
import { store } from '@/redux'

export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: axiosBaseQuery(axios),
  tagTypes: ['sessionsList'],
  endpoints: (build) => ({
    getAllSeasons: build.query<{ data: ISession[] }, void>({
      providesTags: ['sessionsList'],
      query: () => ({
        url: getUrlWithSearchParams('/seasons', {
          expand:
            'importConfig,hasExportConfig,tripsCount,childrenCount,latestFile,files.countNew,files.countUpdate,seasonWeeks,subSeasonWeeks',
        }),
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        try {
          const response = await queryFulfilled

          dispatch({
            type: types.SESSIONS_GET_ALL,
            payload: response.data.data,
          })
          const lsSession = localStorage.getItem('ActiveSessionId')
          const activeSession = (getState() as any).sessions.activeSeason

          if (activeSession?.id) {
            dispatch(currentSession(lsSession ?? response.data.data?.[0].id))
          }
        } catch (e) {
          //
        }
      },
    }),
    addSessionWithExist: build.mutation<
      void,
      Omit<FormType, 'dates' | 'end_date' | 'start_date'>
    >({
      query: (data) => ({
        url: getUrlWithSearchParams('/seasons/copy', data),
      }),
      invalidatesTags: ['sessionsList'],

      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled

          notification.success({
            message: 'The session was successfully added',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error has occurred. The session could not be added.',
          })
        }
      },
    }),
    occupancyReport: build.query<any, number>({
      queryFn: async (seasinId) => {
        const response = await axios.get(
          `${api.gpsV2.defaults.baseURL}/season/bus-summery-report/${seasinId}`,
          {
            responseType: 'arraybuffer',
          }
        )

        return { data: { data: response.data, meta: response.headers } }
      },

      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          const blob = new Blob([response.data?.data], {
            type: response.data?.meta?.['content-type'],
          })
          const fileURL = URL.createObjectURL(blob)
          window.open(fileURL)
        } catch (e) {
          notification.error({
            message: 'Server error',
          })
        }
      },
    }),

    addSession: build.mutation<{ data: any }, Omit<FormType, 'dates'>>({
      query: (data) => ({
        method: 'post',
        url: '/seasons',
        data,
      }),
      invalidatesTags: ['sessionsList'],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled

          notification.success({
            message: 'The session was successfully added',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error has occurred. The session could not be added.',
          })
        }
      },
    }),

    addSubSession: build.mutation({
      query: (data) => ({
        method: 'post',
        url: '../api/v2/season/create-sub-season',
        data,
      }),
      invalidatesTags: ['sessionsList'],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled

          notification.success({
            message: 'The sub session was successfully added',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message:
              'An error has occurred. The sub session could not be added.',
          })
        }
      },
    }),
    editSessionWeekDate: build.mutation({
      query: ({ weekId, data }) => ({
        method: 'put',
        url: `../api/v2/season/week/update/${weekId}`,
        data,
      }),
      invalidatesTags: ['sessionsList'],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled

          notification.success({
            message: 'The week time was successfully updated',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message:
              'An error has occurred. The week time could not be updated.',
          })
        }
      },
    }),
    editNewYearSession: build.mutation<any, { value: any; id: number }>({
      query: ({ id, value }) => ({
        url: `../api/v2/season/new-year/${value ? 'on' : 'off'}/${id}`,
        method: 'post',
      }),
      onQueryStarted: async ({ id, value }, { queryFulfilled, dispatch }) => {
        try {
          useNextYearStore.getState().setIsLoading(true)

          await queryFulfilled

          dispatch(
            sessionApi.util.updateQueryData(
              'getAllSeasons',
              undefined,
              (data) => {
                data.data = data.data.map((item) =>
                  item.id === id
                    ? { ...item, is_next_year: value ? 1 : 0 }
                    : item
                )
              }
            )
          )

          useNextYearStore.getState().setIsLoading(false)
        } catch (e) {
          useNextYearStore.getState().setIsLoading(false)

          notification.error({
            message: 'Server error',
          })
        }
      },
    }),

    copySession: build.mutation<any, number>({
      query: (id) => ({
        url: getUrlWithSearchParams('seasons/copy', { id, isDeleteStop: 0 }),
        method: 'get',
      }),
      onQueryStarted: async (session, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          dispatch(
            sessionApi.util.updateQueryData(
              'getAllSeasons',
              undefined,
              (data) => {
                data.data = [...data.data, response.data?.data]
              }
            )
          )
        } catch (e) {
          notification.error({
            message: 'Server error',
          })
        }
      },
    }),
    deleteSession: build.mutation<any, number[]>({
      queryFn: async (ids) => {
        const request = async (id: number) =>
          await axios.delete(`/seasons/${id}`)

        await Promise.all(ids.map(async (id) => await request(id)))

        return { data: { data: {}, meta: {} } }
      },
      onQueryStarted: async (ids, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          const sessions = sessionApi.endpoints.getAllSeasons.select()(
            store.getState()
          )
          const sessionsIds = sessions.data?.data?.map((session) => session.id)
          const isDeletingCurrentActiveSession = sessionsIds.some((sessionId) =>
            ids.includes(sessionId)
          )
          if (isDeletingCurrentActiveSession) {
            const firstActualSession = sessions.data?.data.find(
              (session) => !ids.includes(session.id)
            )
            dispatch(currentSession(firstActualSession.id))
          }

          dispatch(
            sessionApi.util.updateQueryData(
              'getAllSeasons',
              undefined,
              (data) => {
                data.data = [...(data?.data ?? [])].map((session) =>
                  ids.includes(session.id)
                    ? { ...session, deleting: 1 }
                    : session
                )
              }
            )
          )
        } catch (e) {
          notification.error({
            message: 'Server error',
          })
        }
      },
    }),

    editSessionGroup: build.mutation<any, ISession[]>({
      queryFn: async (sessions) => {
        const editSession = async (session: ISession) =>
          await axios.put<ISession[]>(
            getUrlWithSearchParams(`/seasons/${session.id}`),
            session
          )

        const response = await Promise.all(
          sessions.map(async (session) => await editSession(session))
        )

        return {
          data: {
            data: response.map((item) => item.data),
            meta: {},
          },
        }
      },

      onQueryStarted: async (sessions, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled

          dispatch(
            sessionApi.util.updateQueryData(
              'getAllSeasons',
              undefined,
              (data) => {
                data.data = data.data.map((item) => {
                  const editedSession = sessions.find(
                    (session) => session.id === item.id
                  )
                  if (editedSession) {
                    console.log(editedSession)
                    return editedSession
                  } else {
                    return item
                  }
                })
              }
            )
          )
        } catch (e) {
          notification.error({
            message: 'Server error',
          })
        }
      },
    }),
    editSession: build.mutation<any, ISession>({
      query: (session) => {
        return {
          url: getUrlWithSearchParams(
            `/seasons/${session.id}`,
            {
              expand:
                'tripsCount,childrenCount,latestFile,files.countNew,files.countUpdate',
            },
            { encode: false }
          ),
          method: 'put',
          data: session,
        }
      },

      onQueryStarted: async (session, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          dispatch(
            sessionApi.util.updateQueryData(
              'getAllSeasons',
              undefined,
              (data) => {
                data.data = data.data.map((item) =>
                  item.id === session.id ? session : item
                )
              }
            )
          )
        } catch (e) {
          notification.error({
            message: 'Server error',
          })
        }
      },
    }),
  }),
})

export const {
  useAddSessionWithExistMutation,
  useLazyOccupancyReportQuery,
  useAddSessionMutation,
  useGetAllSeasonsQuery,
  useAddSubSessionMutation,
  useEditSessionWeekDateMutation,
  useEditSessionMutation,
  useEditNewYearSessionMutation,
  useCopySessionMutation,
  useDeleteSessionMutation,
  useEditSessionGroupMutation,
} = sessionApi

export const seasonsSelect = sessionApi.endpoints.getAllSeasons.select()

export const sessionsSelector = createSelector(
  seasonsSelect,
  ({ data }) =>
    data?.data?.filter((session) => !session.isDeleted && !session.deleting) ??
    []
)

export const isSuccessSessionsRequestSelector = createSelector(
  seasonsSelect,
  ({ isSuccess }) => isSuccess
)
