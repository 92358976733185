import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import axios from 'axios'
import { ICompanyCounters, IUserInfo } from '@/store/common/common.types'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import { useRouterStore } from '@/shared/hooks/routeHooks/useRouter'
import * as types from '@/redux/auth/actionTypes'
import { SIGN_IN } from '@/redux/auth/actionTypes'
import { routeLinks } from '@/shared/routeLinks'
import * as Sentry from '@sentry/react'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'
import { notification } from 'antd'
import { setResetFilters } from '@/redux/children/actions'
import { useResetNewFilter } from '@/pages/Riders/components/Filter/FilterNew'
import { CHILDREN_REMOVE_BY_IDS } from '@/redux/children/actionTypes'
import { localUrlGeo } from '@/redux/configLocal'
import {
  IAddress,
  IFieldTripPoint,
  IOSRMResponse,
} from '@/store/fieldTrip/FieldTrip.types'

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: axiosBaseQuery(axios),
  endpoints: (build) => ({
    getCompanyCounters: build.query<{ data: ICompanyCounters }, void>({
      query: () => ({
        url: '../api/v2/company/counters',
      }),
      keepUnusedDataFor: 0,
    }),
    getUserInfo: build.query<{ data: IUserInfo }, void>({
      query: () => ({
        url: getUrlWithSearchParams('users/info', {
          expand:
            'company.address,companyOptions,role,permissions,company.depots',
        }),
        method: 'get',
      }),
      keepUnusedDataFor: 0,

      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const router = useRouterStore.getState().router

        try {
          const { data } = await queryFulfilled
          const companyName = data.data.company.name
          const userName = data.data.first_name
          const lastName = data.data.last_name
          const email = data.data.email
          const role = data.data.role[Object.keys(data.data.role)[0]]
          const roleFull = data.data.role[Object.keys(data.data.role)[0]]
          const permissions = data.data.permissions
          const session_id = data.data.session_id
          const socket_token = data.data.socket_token

          dispatch({
            type: types.COMPANY_WITH_COMPANY,
            payload: data.data.company,
          })

          dispatch({
            type: SIGN_IN,
            payload: {
              user: { companyName, userName, email, lastName },
              isAuth: true,
              errors: false,
              company: data.data.company,
              companyOptions: data.data.companyOptions,
              role: role.data,
              roleFull: roleFull,
              permissions: permissions,
              session_id,
              socket_token,
            },
          })
          Sentry.setUser({ companyName, userName, email, lastName })
        } catch (e) {
          router.push(routeLinks.signin)
          localStorage.removeItem('token')
        }
      },
    }),
    //TODO удалить после того как будут влиты райдеры
    getChildrenStopInfo: build.query<
      any,
      { childAddressId: number; runId: number }
    >({
      keepUnusedDataFor: 0,
      query: ({ childAddressId, runId }) => {
        return {
          url: getUrlWithSearchParams(
            `../api/v2/riders/stop-info/${childAddressId}`,
            {
              runId,
            }
          ),
        }
      },
    }),
    batchDeleteRiders: build.mutation<any, number[]>({
      queryFn: async (ids) => {
        const response = await axios.delete('../api/v2/riders/batch-delete', {
          data: { ids, force: true },
        })
        return { data: { data: response.data, meta: response.headers } }
      },

      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          const addressInput: any = document.getElementById(
            'riders-address-filter'
          )
          const nameInput: any = document.getElementById('riders-name-filter')
          if (addressInput?.value) {
            addressInput.value = ''
          }
          if (nameInput?.value) {
            nameInput.value = ''
          }

          useResetNewFilter.getState().setType('total')
          setTimeout(() => useResetNewFilter.getState().setType(null), 10)

          dispatch({
            type: CHILDREN_REMOVE_BY_IDS,
            payload: ids ?? [],
          })

          dispatch(setResetFilters(true))
          closeModal()
          notification.success({
            message: `${ids.length} riders were successfully removed`,
          })
        } catch (e) {
          notification.error({ message: 'Server error' })
          //
        }
      },
    }),

    getOSRM: build.query<
      { data: IOSRMResponse },
      {
        points: (Pick<IFieldTripPoint, 'waittime' | 'is_anchor' | 'time'> &
          Pick<IAddress, 'lat' | 'lon'>)[]
        steps: 'true' | 'false'
      }
    >({
      keepUnusedDataFor: 0,
      query: (data) => ({
        url: (localUrlGeo as string) ?? '',
        method: 'post',
        data,
      }),
    }),
  }),
})

export const {
  useGetCompanyCountersQuery,
  useGetUserInfoQuery,
  useLazyGetChildrenStopInfoQuery,
  useBatchDeleteRidersMutation,
  useGetOSRMQuery,
} = commonApi
