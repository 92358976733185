import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useContextBuilder } from '../../context'

function EmptyRun() {
  let { setOpenModal } = useContextBuilder()
  return (
    <div className="empty">
      <div className="img" />
      <div className="emptyRunText">
        <Trans>There is No Run Chosen!</Trans>
        <br />
        <Trans>Please Select a Run</Trans>
        <br />
        <Trans>or</Trans>
      </div>
      <div className="emptyRunButton">
        <div
          data-testid="addNewRun"
          className="btnNewRan"
          onClick={() => setOpenModal('modalNewRun')}
        >
          <span>
            <Trans>+ Add New Run</Trans>
          </span>
        </div>
      </div>
    </div>
  )
}

export default EmptyRun
