import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import * as childrenActions from '../../../../../../redux/children/actions'
import { connect } from 'react-redux'
import Col from 'antd/es/col'
import Row from 'antd/es/row'
import { Trans } from 'react-i18next'

import './index.less'
import _ from 'lodash'

import StopChildAddress from '../../../../../Run/components/BuildRun/components/TransferPoint/StopChildAddress'
import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '../../../../../../components/RepeatFunctions/timeWork'
import anchorAndTimeFN from '../../../../../Run/components/BuildRun/components/anchorAndTime'
import { DownIconOutlined, UpIcon } from '../../../../../../img/icons'

class Transfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      PickUp: 'show',
      DropOff: 'show',
    }
  }

  handleClick = (e, name) => {
    this.setState({
      show: !this.state.show,
    })
    if (name === 'default') {
      this.setState({
        edit: !this.state.edit,
      })
    }
  }

  componentDidMount() {
    this.getRunName()
    if (this.props.companyOptions.expand_riders_in_view_runs) {
      this.setState({
        show: true,
      })
    }
  }

  getRunName = () => {
    let { routePointRoutes, routeId } = this.state

    let selectRuns

    if (!routePointRoutes) {
      return false
    }

    let run = routePointRoutes.filter((x) => x.route_id !== routeId)

    if (!_.isEmpty(run)) {
      let id = run[0].route_id

      this.state.routes.forEach((item) => {
        item.routes.forEach((x) => {
          if (x.id === id) {
            if (x) {
              selectRuns = x
            }
          }
        })
      })

      if (selectRuns) {
        this.setState({
          selectTitle: selectRuns.title,
        })
      }
    }
  }

  render() {
    let { id, address, busStopTitle, routePointTransferChild } = this.state

    let { pointOsrmLeg } = this.props

    let arriveTime = null
    let distance = null
    let duration = null

    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)

      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }

    let position = this.state.routePointRoute.view_position

    let pickUpChild = routePointTransferChild.filter(
      (x) => x.childAddress.type === 1
    )
    let dropOffChild = routePointTransferChild.filter(
      (x) => x.childAddress.type === 2
    )

    if (!address) {
      return null
    }

    return (
      <div key={id} className="Point">
        <div className="Transfers">
          {busStopTitle && (
            <Row className="MarginBlock noMarginTop">
              <Col span={24} className="fullName">
                {position + '.'} {busStopTitle ? busStopTitle : null}
              </Col>
            </Row>
          )}

          <Row className="marginTopEight">
            <Col span={12} className={'dist'}>
              {distance && duration && (
                <div>
                  <span>{distance}</span>
                  <span>{duration}</span>
                </div>
              )}
            </Col>
            <Col span={24} className={'waitArr'}>
              {anchorAndTimeFN.bind(this)()}
            </Col>
          </Row>
          <Row>
            <Col span={12} className={'dist'}>
              <div className={'TransferRun'}>
                <span
                  className="key"
                  style={{ color: '#8d8d8d', marginRight: 0 }}
                >
                  Run{' '}
                </span>
                <span
                  className="val"
                  style={{ fontFamily: `"SBM-Medium", sans-serif` }}
                >
                  {this.state.selectTitle}
                </span>
              </div>
            </Col>
          </Row>

          <Row className="Info">
            {this.props.first && (
              <Row>
                <Col span={24} className="text">
                  <Trans>The Run Starts from here</Trans>
                </Col>
              </Row>
            )}

            {address && address.address && (
              <Row>
                <Col span={24}>
                  <div className={'alignAddress'}>
                    <span className="Address noBackgroundPadd">Address</span>
                    {address.lat} {address.lon}
                  </div>
                </Col>
              </Row>
            )}

            <div style={{ width: '100%' }}>
              {routePointTransferChild.length ? (
                <div className="SchoolAddress MarginBlock">
                  <span className="riders">
                    Riders {routePointTransferChild.length}
                  </span>
                  {this.state.show ? (
                    <UpIcon onClick={this.handleClick} />
                  ) : (
                    <DownIconOutlined onClick={this.handleClick} />
                  )}
                </div>
              ) : null}

              {this.state.show && (
                <div className="PuckUpMain marginTop">
                  <div className="pickUpBlock">
                    {pickUpChild.length > 0 && (
                      <div className="pickUp">Pick Up Riders</div>
                    )}

                    {pickUpChild.map((address, index) => {
                      return (
                        <StopChildAddress
                          key={index}
                          phone={true}
                          address={address}
                          readOnly={true}
                        />
                      )
                    })}
                  </div>

                  <div
                    className={`dropOffBlock ${
                      pickUpChild.length > 0 ? `marginTop` : ''
                    }`}
                  >
                    {dropOffChild.length > 0 && (
                      <div className="dropOff">Drop Off Riders</div>
                    )}
                    {dropOffChild.map((address, index) => {
                      return (
                        <StopChildAddress
                          key={index}
                          phone={true}
                          address={address}
                          readOnly={true}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </Row>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    companyOptions: state.auth.companyOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { runActions, childrenActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transfer)
