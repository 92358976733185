import { Editor } from '@tinymce/tinymce-react'
import { Button } from '@/entities/ui/NewButton/NewButton'

import styles from './OldVariant.module.less'
import { useMemo, useState } from 'react'
import { Spin } from 'antd'
import classnames from 'classnames'
import { BaseInput } from '@/entities/ui/Inputs/BaseInput/BaseInput'
import { useAppSelector } from '@/redux'
import { getInit } from '@/entities/modals/ParentEmailModal/ParentEmailModal.helpers'
import { useOldVariantParentEmailModalConfig } from '@/entities/modals/ParentEmailModal/components/variants/OldVariant/useOldVariantParentEmailModalConfig'

export const OldVariant = ({ onCloseModal, arrayId }) => {
  const { initialValue, isLoading, onSend, onSave, onInit } =
    useOldVariantParentEmailModalConfig(arrayId)
  const companyInfo = useAppSelector((state) => state.auth)

  const childrenList = useAppSelector((state) =>
    state.children.childrenList?.filter(({ id }) => arrayId.includes(id))
  )

  const totalEmailsNumbers = useMemo(
    () =>
      childrenList?.reduce(
        (totalChild, child) =>
          totalChild +
          Object.keys(child).reduce((total, acc) => {
            if (
              (companyInfo?.company?.enabledEmails ?? []).includes(acc) &&
              child[acc]
            ) {
              return total + 1
            }
            return total
          }, 0),
        0
      ),
    [childrenList, companyInfo?.company?.enabledEmails]
  )

  const [customSubject, setCustomSubject] = useState('')
  const [isLoadingTiny, setIsLoadingTiny] = useState(true)
  const placeholder = companyInfo.company.camp_mode
    ? companyInfo.company.display_name + ' weekly camp email'
    : "`Rider's name` school bus schedule"

  return (
    <>
      {isLoadingTiny && <Spin className={styles.spin} />}

      <div
        className={classnames(styles.editorWrapper, {
          [styles.loading]: isLoadingTiny,
        })}
      >
        <BaseInput
          style={{ marginBottom: 8 }}
          label={'Input custom email subject or leave default one.'}
          value={customSubject}
          onChange={(e) => setCustomSubject(e.target.value)}
          placeholder={placeholder}
        />

        <Editor
          onLoadContent={(props) => {
            if (props.load) {
              setIsLoadingTiny(false)
            }
          }}
          apiKey="aqum6p918ap6qjt6h2hy5aiccgjp1yu741up5nekale0v2st"
          onInit={onInit}
          initialValue={initialValue}
          init={getInit()}
        />
      </div>

      <p className={styles.title}>
        You are about to send an email to all selected rider’s parents (
        <b>{totalEmailsNumbers}</b> emails).
      </p>
      <div className={styles.footer}>
        <Button buttonColor="gray" onClick={onCloseModal} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={onSave} disabled={isLoading || !totalEmailsNumbers}>
          Save&Send
        </Button>
        <Button
          onClick={() => onSend(customSubject)}
          disabled={isLoading || !totalEmailsNumbers}
          isLoading={isLoading}
        >
          Send
        </Button>
      </div>
    </>
  )
}
