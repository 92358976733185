import { create } from 'zustand'

export interface IModalStore {
  currentModal: ModalsEnum | null
  state?: any
  onCloseModal: () => void
  onOpenModal: (params: { currentModal: ModalsEnum; state?: any }) => void
}

const initialState = {
  currentModal: null,
  state: {},
}

export const useModalStore = create<IModalStore>((set, get) => ({
  ...initialState,
  onCloseModal: () => set((state) => ({ ...state, ...initialState })),
  onOpenModal: (params) => set(() => ({ ...get(), ...params })),
}))

export enum ModalsEnum {
  COMPANY_INFO_EDIT = 'COMPANY_INFO_EDIT',
  PRINT_MODAL = 'PRINT_MODAL',
  PARENT_EMAIL = 'PARENT_EMAIL',
  ALERT_PARENT_EMAIL = 'ALERT_PARENT_EMAIL',
  ADD_NEW_SESSION = 'ADD_NEW_SESSION',
  ADD_SUB_SESSION = 'ADD_SUB_SESSION',
  EDIT_SESSION_WEEK = 'EDIT_SESSION_WEEK',
  IMPORTS_RIDERS = 'IMPORTS_RIDERS',
  OPENED_PARENT_REQUEST = 'OPENED_PARENT_REQUEST',
  CLOSED_PARENT_REQUEST = 'CLOSED_PARENT_REQUEST',
  ADD_BUS_DEPOT = 'ADD_BUS_DEPOT',
  ADD_BUS_MODAL = 'ADD_BUS_MODAL',
  EDIT_BUS_DEPOT = 'EDIT_BUS_DEPOT',
  DELETE_BUS_DEPOT = 'DELETE_BUS_DEPOT',
  DELETE_BUS_MODAL = 'DELETE_BUS_MODAL',
  DELETE_DRIVER_MODAL = 'DELETE_DRIVER_MODAL',
  ADD_DRIVER_MODAL = 'ADD_DRIVER_MODAL',
  RT_REGISTRATIONS_OPENED_MODAL = 'RT_REGISTRATIONS_OPENED_MODAL',
  DELETE_FT_REQUEST_MODAL = 'DELETE_FT_REQUEST_MODAL',
  EDIT_FIELD_TRIP_MODAL = 'EDIT_FIELD_TRIP_MODAL',
  RESEND_TO_APPROVER = 'RESEND_TO_APPROVER',
  CREATE_USER_MODAL = 'CREATE_USER_MODAL',
  OPTIMIZE_RUN_MODAL = 'OPTIMIZE_RUN_MODAL',
}

export const useModalSelector = (modal?: ModalsEnum) => {
  const store = useModalStore((state) => state)
  return { ...store, isOpen: modal === store.currentModal }
}
