import React, { Component, memo } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'
import { ModalAnchor } from '../../../ModalAnchor'
import i18n from '../../../../../../i18n'
import { RightOutlined } from '@ant-design/icons'

import { addressChard2 } from '@/components/RepeatFunctions/addressFrequency'
import Modal from './../Modal/DeleteStop/index'
import AllPurpose from './../Modal/AllPurpose/index'
import MoreBlock from '../BlockMore/index'
import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '@/components/RepeatFunctions/timeWork'
import anchorAndTimeFN from '../anchorAndTime'
import { Trans } from 'react-i18next'
import ModalAddToTransfer from '../StopPoint/ModalAddToTransfer'
import classNames from 'classnames'
import classnames from 'classnames'
import styles from '../BusDepot/index.module.less'
import { useContextBuilder } from '@/pages/Run/components/BuildRun/context'
import { AttendanceGroup } from '@/pages/Run/components/BuildRun/components/AttendanceGroup/AttendanceGroup'

export const MiddlewareChildren = memo((props) => {
  const errorAddress = !props.address.lat || !props.address.lat
  let { activeRun } = useContextBuilder()

  return (
    <div>
      <Child
        // 1 = PU 2 = DO
        runType={activeRun.is_morning}
        errorAddress={errorAddress}
        {...props}
      />
    </div>
  )
})

class Child extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      dropTargetPosition: 'BOTTOM',
      edit: props.errorAddress,
      showModalAnchor: false,
      Edit: false,
      editStop: false,
      anchorItem: '',
      index: props.index3,
      arrive: props.Arrive,
      Wait: props.Wait,
      miles: props.miles,
      min: props.min,
      AllPurpose: [],
      curbside_pickup: 0,
      use_default_settings: 0,
      variant: props?.variant,
    }
    this.min = 0
    this.miles = 0
    this.time = ''
  }

  componentDidMount() {
    this.obj = {
      title: i18n.t('Edit Bus Depot Stop'),
      select: [
        {
          label: i18n.t('Rider'),
          name: 'rider',
        },
      ],
      minute: [
        {
          label: i18n.t('Wait Time'),
          placeholder: i18n.t('In Minutes'),
        },
      ],
      stops: [
        {
          label: i18n.t('Enter Stop Number'),
          placeholder: i18n.t('or Stop Will Be Placed Last on the List'),
        },
      ],
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...nextProps,
    })
  }

  fn = () => {
    console.log('test')
  }

  openModalAnchor = () => {
    this.setState({
      showModalAnchor: true,
    })
  }

  saveAnchor = (data) => {
    this.props.dispatch(runActions.editAnchor(data))
  }

  closeModal = () => {
    this.setState({
      showModalAnchor: false,
      removeModal: false,
      Edit: false,
      editStop: false,
    })
  }

  setDropTarget(bottom) {
    if (bottom) {
      this.setState({
        dropTargetPosition: 'BOTTOM',
      })
    } else {
      this.setState({
        dropTargetPosition: 'TOP',
      })
    }
  }

  handleEditStop = () => {
    this.setState({
      editStop: true,
    })
  }

  editToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleChange = (type, e) => {
    e.preventDefault()
    if (type === 'newPosition') {
      //this.props.changePosFn(idElement, newPosition, inStop, child_address_id)
      if (e.target.value) this.props.changePosFn(this.state.id, e.target.value)
    }
    this.setState({
      [type]: e.target.value,
    })
  }

  removePoint = () => {
    this.props.dispatch(runActions.delPosition(this.state.id))
  }

  removeRun = (e, id) => {
    this.setState({
      removeModal: true,
    })
  }

  handleStop = () => {
    this.setState({
      editStop: true,
    })
  }

  Edit = (e, id) => {
    this.setState({
      Edit: true,
    })
  }

  handleAnchor = () => {
    return (
      !this.props.isWriteRoutes &&
      this.setState({
        showModalAnchor: true,
      })
    )
  }

  deletePoint = () => {
    this.props.delFn()
    this.closeModal()
  }

  getSchools = (schoolId) => {
    let schools = this.props.schools
    return (
      schools && schools.length && schools.find((x) => x.id === schoolId).name
    )
  }

  stopRidersToTransfer = () => {
    this.setState({
      modalToTransfer: true,
      point: {
        stopId: this.props.id,
        child_address_id: this.props.routePointChildren[0].child_address_id,
      },
    })
  }

  render() {
    let { address, object, id, routePointRoute, lastIndex, isAnchor, routeId } =
      this.state
    let children = object
    let { schools, routeWaittime, pointOsrmLeg, route, routePointChildren } =
      this.props

    let newPosition = this.props.routePointRoute.view_position

    let times, WeekName
    // if (children.session_times) {
    //   times = JSON.parse(children.session_times);
    //   if (Object.keys(times)[0] !== "0") {
    //     WeekName = Object.keys(times)[0];
    //   }
    // }

    if (!this.showEdit) {
      this.showEdit = true
    }

    let arriveTime = null
    let distance = null
    let duration = null
    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)

      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }
    let childType
    let calendar
    if (routePointChildren[0] && routePointChildren[0].childaddress) {
      childType = routePointChildren[0].childaddress.type
      calendar = this.state.routePointChildren[0].childaddress
    }
    const isError =
      !children ||
      (address && (!address.lat || !address.lon) && this.state.edit)

    return (
      <div className="ListDiv">
        <div
          data-testid="childPoint"
          key={id}
          className={classNames(
            'childPoint',
            'Green',
            { error: isError || this.props.errorAddress },
            { bgTranscent: !isError }
          )}
        >
          {!!children && (
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className="buttonTrigger"
                onClick={(e) => this.editToggle(e)}
              >
                <RightOutlined
                  style={{ color: 'white' }}
                  className={classnames(styles.arrow, {
                    [styles.active]: this.state.edit,
                  })}
                />
              </div>
            </React.Fragment>
          )}
          {!this.props.isWriteRoutes && (
            <MoreBlock
              isLast={newPosition === lastIndex}
              routePointRoute={routePointRoute}
              id={id}
              handleAnchor={this.handleAnchor}
              handleEditStop={this.handleStop}
              removeRun={this.removeRun}
              type={1}
              route={route}
              showTransfer={this.props.runType === 1}
              stopRidersToTransfer={this.stopRidersToTransfer}
            />
          )}
          <div className="Head">
            <div className="big">
              {newPosition}. {children ? children.full_name : 'Deleted rider'}
            </div>
            {distance && duration && (
              <div className="normal distance">
                <span>{distance}</span>
                <span>{duration}</span>
              </div>
            )}
          </div>
          {anchorAndTimeFN.bind(this)()}

          <div className={styles.attendanceGroup}>
            <AttendanceGroup routePointRoute={routePointRoute} />
          </div>

          {this.state.edit && (
            <div>
              <div
                className={` ${
                  !address.lat
                    ? 'riderInfo pickUpList InvalidAddress'
                    : 'riderInfo pickUpList'
                }`}
              >
                <div className="marginBttom">
                  {childType && (
                    <span className="SchoolNames">
                      <b>{childType === 1 ? 'PU' : 'DO'}</b>

                      {!address.lat || !address.lon ? (
                        <span className="Error">
                          <Trans>Invalid Address</Trans>{' '}
                        </span>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <span className="icon" /> {address.address}
                        </div>
                      )}
                    </span>
                  )}
                  <span className="Days"> {addressChard2(calendar)}</span>
                </div>
                {address.address && (
                  <div className="marginBttom">
                    <span className="Address">
                      {' '}
                      {this.getSchools(calendar.childSchool.school_id)}
                    </span>

                    {children.classs && (
                      <div className="school">
                        <span className="normal">
                          {children.classs.replace('th', '')}
                          <Trans>th</Trans>{' '}
                          <span className="gray">
                            <Trans>Grade</Trans>
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                )}

                <div className="calendars marginBttom">
                  <div className="sessions">
                    {/*<SessionTimes*/}
                    {/*  item={children.session_times}*/}
                    {/*  type="getTimesForRunPage"*/}
                    {/*/>*/}
                  </div>
                  <div className="marginBttom">{WeekName}</div>
                </div>
              </div>
            </div>
          )}
        </div>

        {this.state.removeModal && (
          <Modal
            closeFn={this.closeModal}
            title={i18n.t('Delete Rider')}
            deleteBtn={i18n.t('Delete Rider')}
            child={true}
            deleteObject={
              children ? this.state.object.full_name : 'Deleted rider'
            }
            deleteFn={() =>
              this.deletePoint(
                id,
                this.state.position,
                children.id,
                this.state.address.id,
                this.state.type
              )
            }
          />
        )}
        {this.state.editStop && (
          <AllPurpose
            id={id}
            rend={this.props.getActiveRun}
            select={this.obj.select}
            routeId={routeId}
            positon={newPosition}
            waittime={routeWaittime}
            minute={this.obj.minute}
            stops={this.obj.stops}
            title={i18n.t('Edit Rider Bus Stop')}
            closeFn={this.closeModal}
          >
            {children}
          </AllPurpose>
        )}
        {this.state.showModalAnchor && (
          <ModalAnchor
            data={{
              id: id,
              routeId: routePointRoute['route_id'],
              name: children.full_name,
              type: i18n.t('Rider Stop'),
              position: newPosition,
              address: address,
              time: routePointRoute.time,
              waittime: routePointRoute.waittime,
              isAnchor: routePointRoute.is_anchor,
              routeTime: routePointRoute.routeTime,
              timeZone: this.props.timeZone,
            }}
            saveFn={this.saveAnchor}
            closeFn={this.closeModal}
          />
        )}

        {this.state.modalToTransfer && (
          <ModalAddToTransfer
            closeFn={() => this.setState({ modalToTransfer: false })}
            point={this.state.point}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    schools: state.schools,
    miles: state.routesView.Miles,
    Wait: state.routesView.Wait,
    Arrive: state.routesView.Arrive,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { runActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Child)
