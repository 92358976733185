import React from 'react'
import { MiddlewareChildren } from '../ChildPoint'
import Stop from '../StopPoint'
import School from '../SchoolPoint'
import Redirection from '../RedirectionPoint'
import Transfer from '../TransferPoint'
import BusDepot from '../BusDepot'
import { useDispatch } from 'react-redux'

function DragItems(data) {
  const dispatch = useDispatch()
  let config = {
    newPosition: data.position,
    isLast: data.isLast,
    getActiveRun: data.getActiveRun,
    lastIndex: data.lastIndex,
    delFn: data.delFn,
    schools: data.schools,
    route: data.route,
    index: data.i,
    key: data.id,
    pointOsrmLeg: data.pointOsrmLeg,
    changePosFn: data.changePosFn,
    timeZone: data.route.time_zone,
    ...data.elem,
    removeChildFromStop: data.removeChildFromStop,
    removeChildFromTransfer: data.removeChildFromTransfer,
    isWriteRoutes: data.isWriteRoutes,
    dispatch: dispatch,
    variant: 'RUN_BUILDER',
  }
  // eslint-disable-next-line default-case
  switch (data.elem.type) {
    case 1: //child ЗЕЛЕНЫЙ
      return <MiddlewareChildren {...config} />

    case 2: //BusStop  ТЕМНО ЗЕЛЕНЫЙ
      return <Stop {...config} />

    case 3: //school ОРАНЖЕАЯ
      return <School {...config} />

    case 4: //redirectionPoint  Redirection
      return <Redirection {...config} />

    case 5: //transfer CЕРЕНЕВЫЙ
      return <Transfer {...config} />

    case 6: //bus depot ТЕМНОГО
      return <BusDepot {...config} />
  }
}

export default DragItems
