import { ParentEmailModal } from '@/entities/modals/ParentEmailModal/ParentEmailModal'
import { RidersImportModal } from '@/entities/modals/RidersImportModal/RidersImportModal'
import { RequestsOpenedModal } from '@/entities/modals/Requests/RequestsOpenedModal/RequestsOpenedModal'
import { RequestsClosedModal } from '@/entities/modals/Requests/RequestsClosedModal/RequestsClosedModal'
import { useMemo } from 'react'
import { AddBusDepot } from '@/entities/modals/AddBusDepot/AddBusDepot'
import { EditBusDepotModal } from '@/entities/modals/EditBusDepot/EditBusDepot'
import { DeleteBusDepotModal } from '@/entities/modals/DeleteBusDepotModal/DeleteBusDepotModal'
import { DeleteBusModal } from '@/entities/modals/DeleteBusModal/DeleteBusModal'
import { AddBusModal } from '@/entities/modals/AddBusModal/AddBusModal'
import { AddDriverModal } from '@/entities/modals/AddDriverModal/AddDriverModal'
import { DeleteDriverModal } from '@/entities/modals/DeleteDriverModal/DeleteDriverModal'
import { OptimizeRunModal } from '@/entities/modals/OptimizeRunModal/OptimizeRunModal'
import { RTRegistrationsOpenedModal } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/RTRegistrationsOpenedModal'
import { DeleteFTRequestModal } from '@/entities/modals/DeleteFTRequestModal/DeleteFTRequestModal'
import { EditFieldTripModal } from '@/entities/modals/EditFieldTripModal/EditFieldTripModal'
import { ResendToApproverModal } from '@/entities/modals/ResendToApproverModal/ResendToApproverModal'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import {
  modalConfig,
  useModalFlowStore,
} from '@/features/containers/ModalContainer/modalConfig'
import { AlertParentEmailModal } from '@/entities/modals/AlertParentEmailModal/AlertParentEmailModal'

const ModalRender = () => {
  const { currentModal, modalProps, modalSettings, onClose } =
    useModalFlowStore((state) => state)
  const ModalComponent = useMemo(
    () => (currentModal ? modalConfig[currentModal] : () => <></>),
    [currentModal]
  )

  // useEffect(() => {
  //   openModal({
  //     currentModal: 'cloneRunModal',
  //     modalProps: {
  //       runId: 35887,
  //     },
  //     modalSettings: {
  //       width: 600,
  //     },
  //   })
  // }, [])

  return currentModal ? (
    <NewModal
      onClose={modalSettings?.disableClose ? () => null : onClose}
      open={!!currentModal}
      {...(modalSettings ?? {})}
    >
      <ModalComponent {...(modalProps ?? {})} />
    </NewModal>
  ) : null
}

export const ModalContainer = () => {
  return (
    <>
      <ModalRender />
      <AlertParentEmailModal />
      <DeleteDriverModal />
      <AddDriverModal />
      <AddBusDepot />
      <AddBusModal />
      <EditBusDepotModal />
      <DeleteBusDepotModal />
      <ParentEmailModal />
      <RidersImportModal />
      <RequestsOpenedModal />
      <RequestsClosedModal />
      <DeleteBusModal />
      <OptimizeRunModal />
      <RTRegistrationsOpenedModal />
      <DeleteFTRequestModal />
      <EditFieldTripModal />
      <ResendToApproverModal />
    </>
  )
}
