import React, { Component } from 'react'
import Modal from '../../../../../../components/BaseModal'
import * as routeAction from '../../../../../../redux/routesView/actions'
import i18n from '../../../../../../i18n'

import Row from 'antd/es/row/index'
import Col from 'antd/es/col/index'
import Select from 'antd/es/select'
import Radio from 'antd/es/radio/index'

import './index.less'

import _ from 'lodash'
import { filterArrByAbcInt } from '../../../../../../helpers/arrayWork'
import { SuffixIcon } from '../../../../../../img/iconsComponents/SuffixIcon'
import { useAppDispatch, useAppSelector } from '@/redux'
import {
  useGetBusDepotListQuery,
  useGetBusesQuery,
  useGetDriversQuery,
} from '@/store/resources/resources'
import { BaseInput } from '@/entities/ui/Inputs/BaseInput/BaseInput'

class _ModalAdd extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: 'tags',
      bus_depot_id: null,
      is_morning: 1,
      depots: [],
      driver_id: null,
      drivers: [],

      bus_id: null,
      buses: [],

      groupName: 'no group',
      formErrors: {
        title: false,
        bus_id: false,
        bus_depot_id: false,
      },
      formEmpty: {
        title: true,
        bus_id: true,
        bus_depot_id: true,
      },
      titleLength: false,
    }
  }

  componentDidMount() {
    if (this.props.drivers) this.driversFill()
    if (this.props.depots) this.depotsFill()
    if (this.props.buses) this.busesFill()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.drivers !== this.props.drivers) this.driversFill()
    if (prevProps.depots !== this.props.depots) this.depotsFill()
    if (prevProps.buses !== this.props.buses) this.busesFill()
  }

  driversFill = () => {
    let driversF = this.props.drivers.filter((x) => x.is_active)
    driversF = filterArrByAbcInt(driversF, 'name')

    this.setState({
      drivers: driversF,
      driver_id: driversF[0] ? driversF[0].id : null,
    })
  }

  depotsFill = () => {
    let depotsF = filterArrByAbcInt(this.props.depots, 'name')
    this.setState({
      depots: depotsF,
      bus_depot_id: !_.isEmpty(depotsF) && depotsF[0].id,
    })
  }

  busesFill = () => {
    let busesF = this.props.buses.filter((x) => x.is_active)
    busesF = filterArrByAbcInt(busesF, `bus_number`)
    this.setState({
      buses: busesF,
    })
  }

  saveForm = () => {
    if (!this.state.bus_depot_id) {
      this.setState({
        bus_depot_id: !_.isEmpty(this.state.depots)
          ? this.state.depots[0].id
          : 0,
      })
    } else {
      let errors = this.state.formEmpty
      errors.bus_depot_id = false

      this.setState({
        formEmpty: errors,
      })
    }

    if (this.state.formEmpty.title) {
      let errors = this.state.formErrors
      errors.title = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formEmpty.bus_id) {
      let errors = this.state.formErrors
      errors.bus_id = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formEmpty.bus_depot_id) {
      let errors = this.state.formErrors
      errors.bus_depot_id = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formErrors.bus_id) {
      return
    }
    if (this.state.formErrors.title) {
      return
    }

    if (this.state.formErrors.bus_depot_id) {
      return
    }

    if (this.state.titleLength) {
      return
    }

    // if (!this.state.groupName) {
    //   //TODO Бэк считает что  "" и null  это разные группы
    //   this.setState({
    //     groupName: null
    //   });
    // }
    const sessionId = this.props.activeSeason.id

    if (!this.state.bus_id) {
      this.state.bus_id = null
    }
    if (!this.state.bus_depot_id) {
      this.state.bus_depot_id = null
    }
    if (!this.state.driver_id) {
      this.state.driver_id = null
    }

    this.props.dispatch(routeAction.addItem(this.state, sessionId))
    this.props.closeFn()
  }

  handleChange = (type, e) => {
    e.preventDefault()
    this.setState({
      [type]: e.target.value,
    })

    let errors = this.state.formErrors
    let empty = this.state.formEmpty

    if (type === 'title' && e.target.value.length > 64) {
      this.setState({
        titleLength: true,
      })
      errors[type] = true
    } else {
      this.setState({
        titleLength: false,
      })
    }

    if (!e.target.value || e.target.value == '') {
      errors[type] = true
      empty[type] = true
    } else {
      errors[type] = false
      empty[type] = false
    }

    this.setState({
      formErrors: errors,
      formEmpty: empty,
    })
  }

  selectSettings = (id, type) => {
    let errors = this.state.formErrors
    let empty = this.state.formEmpty

    if (!id && type !== 'driver_id') {
      errors[type] = true
      empty[type] = true
    } else {
      errors[type] = false
      empty[type] = false
    }

    this.setState({
      formErrors: errors,
      formEmpty: empty,
    })

    this.setState({
      [type]: id,
    })
  }

  handle = (bool) => {
    this.setState({
      return_to_depot: bool ? 1 : 0,
    })
  }

  selectRouteName = (v) => {
    this.setState({
      groupName: v[0],
    })
  }

  disabled = () => {
    const selects = document.getElementsByClassName(`ant-select1-search__field`)
    if (this.state.groupName && this.state.groupName !== '') {
      for (let el of selects) {
        el.setAttribute(`maxlength`, 0)
      }
    } else {
      for (let el of selects) {
        el.setAttribute(`maxlength`, 99999999999)
      }
    }
  }

  render() {
    let close = this.props.closeFn
    const RadioGroup = Radio.Group

    let { buses, drivers, bus_id, driver_id } = this.state

    this.disabled()

    return (
      <Modal
        title={i18n.t('Add a New Run')}
        id={'ModalNewRun'}
        saveBtn={i18n.t('Save')}
        closeBtn={i18n.t('Cancel')}
        class={'ModalEdit open'}
        closeFn={close}
        save={this.saveForm}
      >
        <div className="profile padd15">
          <div>
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <BaseInput
                  onChange={(e) => this.handleChange('title', e)}
                  placeholder="Name a new Run"
                  error={
                    this.state.formErrors.title || this.state.titleLength
                      ? 'Run name should contain at most 64 characters'
                      : undefined
                  }
                  data-testid="runNameInp"
                  label={'Run Name'}
                />
              </Col>
            </Row>

            <Row style={{ width: '100%' }} className="mgTop12">
              <Col span={24}>
                <div className="small">{i18n.t('Driver Name')}</div>

                <Select
                  data-testid="driverSelect"
                  style={{ width: '100%' }}
                  onChange={(id) => this.selectSettings(id, 'driver_id')}
                  placeholder={i18n.t('Select Driver')}
                  className={'selW100%'}
                  showSearch={true}
                  optionFilterProp="value"
                  filterOption={(input, option) => {
                    if (
                      option.props.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    )
                      return true
                  }}
                  suffixIcon={
                    <SuffixIcon
                      style={{
                        top: '2px',
                        position: 'relative',
                        right: '-6px',
                      }}
                    />
                  }
                  value={driver_id || undefined}
                >
                  {drivers.map((driver) => {
                    return (
                      <Select.Option key={driver.id} value={driver.id}>
                        {driver.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Col>
            </Row>

            <Row style={{ width: '100%' }} className="mgTop12">
              <Col span={24}>
                <BaseInput
                  onChange={(e) => this.handleChange('monitor_name', e)}
                  placeholder="Monitor Name"
                  label={'Monitor Name'}
                />
              </Col>
            </Row>

            <Row style={{ width: '100%' }} className="mgTop12">
              <Col span={24}>
                <div
                  className={
                    this.state.formErrors.bus_id ? 'error small' : 'small'
                  }
                >
                  {i18n.t('Bus #')}
                </div>
                <Select
                  data-testid="busSelect"
                  style={{ width: '100%' }}
                  className={this.state.formErrors.bus_id ? 'error' : ''}
                  onChange={(id) => this.selectSettings(id, 'bus_id')}
                  placeholder={i18n.t('Bus #')}
                  suffixIcon={
                    <SuffixIcon
                      style={{
                        top: '2px',
                        position: 'relative',
                        right: '-6px',
                      }}
                    />
                  }
                  value={bus_id ? bus_id : 0}
                >
                  <option
                    className="hide"
                    style={{ color: 'rgba(0,0,0,0.5)' }}
                    value={0}
                  >
                    {i18n.t('Bus #')}
                  </option>
                  {buses.map((bus) => {
                    return (
                      <option key={bus.id} value={bus.id}>
                        {bus.bus_number}
                      </option>
                    )
                  })}
                </Select>
              </Col>
            </Row>

            <Row style={{ width: '100%' }} className="mgTop12">
              <Col span={24}>
                <div
                  className={
                    this.state.formErrors.bus_depot_id ? 'error small' : 'small'
                  }
                >
                  {i18n.t('Select a Bus Depot for this Run')}
                </div>
                <Select
                  data-testid="depotSelect"
                  style={{ width: '100%' }}
                  className={this.state.formErrors.bus_depot_id ? 'error' : ''}
                  onChange={(id) => this.selectSettings(id, 'bus_depot_id')}
                  placeholder={i18n.t('Select Bus Depot')}
                  suffixIcon={
                    <SuffixIcon
                      style={{
                        top: '2px',
                        position: 'relative',
                        right: '-6px',
                      }}
                    />
                  }
                  value={this.state.bus_depot_id}
                >
                  <option
                    className="hide"
                    style={{ color: 'rgba(0,0,0,0.5)' }}
                    value={0}
                  >
                    {i18n.t('Select Bus Depot')}
                  </option>
                  {this.state.depots.map((depot) => {
                    return (
                      <option key={depot.id} value={depot.id}>
                        {depot.name}
                      </option>
                    )
                  })}
                </Select>
              </Col>
            </Row>

            <Row style={{ width: '100%' }}>
              <RadioGroup
                className="radio"
                defaultValue={this.state.is_morning}
                onChange={(e) => this.handleChange('is_morning', e)}
              >
                <Radio
                  data-testid="pickUpCheckbox"
                  className="normal"
                  value={1}
                >
                  {i18n.t('Pick Up Run')}
                </Radio>
                <Radio
                  data-testid="dropOffCheckbox"
                  className="normal"
                  value={2}
                >
                  {i18n.t('Drop Off Run')}
                </Radio>
              </RadioGroup>
            </Row>
          </div>
        </div>

        <div className="center" style={{ paddingBottom: '15px' }}>
          <div className="buttons">
            <button onClick={close} className="cancel">
              {i18n.t('Cancel')}
            </button>
            <button
              data-testid="saveRunBtn"
              onClick={this.saveForm}
              className="save"
            >
              {i18n.t('Save')}
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

const ModalAdd = (props) => {
  const params = useAppSelector((state) => {
    return {
      routes: state.routesView.routes,
      depots: state.depots,
      activeSeason: state.sessions.activeSeason,
    }
  })
  const dispatch = useAppDispatch()
  const buses = useGetBusesQuery()
  const drivers = useGetDriversQuery()
  const depots = useGetBusDepotListQuery()

  return (
    <_ModalAdd
      {...params}
      dispatch={dispatch}
      drivers={drivers?.data?.data ?? []}
      buses={buses.data?.data ?? []}
      depots={depots.data?.data ?? []}
      {...props}
    />
  )
}

export default ModalAdd
