import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'
import { ModalAnchor } from '../../../ModalAnchor'
import AllPurpose from './../Modal/AllPurpose/index'
import anchorAndTimeFN from '../anchorAndTime'
import Modal from './../Modal/DeleteStop/index'
import i18n from '../../../../../../i18n'
import styles from './index.module.less'
import classnames from 'classnames'
import MoreBlock from '../BlockMore'
import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '../../../../../../components/RepeatFunctions/timeWork'
import { Trans } from 'react-i18next'
import { RightIcon } from '../../../../../../img/icons'
import { AttendanceGroup } from '@/pages/Run/components/BuildRun/components/AttendanceGroup/AttendanceGroup'

class BusDepot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      showModalAnchor: false,
      anchorItem: '',
      edit: false,
      index: props.index3,
      arrive: props.Arrive,
      Wait: props.Wait,
      miles: props.miles,
      min: props.min,
      curbside_pickup: 0,
      use_default_settings: 0,
    }
    this.min = 0
    this.miles = 0
    this.wait = 0
  }

  componentDidMount() {
    this.obj = {
      select: [
        {
          label: i18n.t('Depot'),
          name: 'depot',
        },
      ],
      minute: [
        {
          label: i18n.t('Wait Time'),
          placeholder: i18n.t('In Minutes'),
        },
      ],
      stops: [
        {
          label: i18n.t('Enter Stop Number'),
          placeholder: i18n.t('or Stop Will Be Placed Last on the List'),
        },
      ],
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...nextProps,
    })
  }

  openModalAnchor = () => {
    this.setState({
      showModalAnchor: true,
    })
  }

  closeModal = () => {
    this.setState({
      showModalAnchor: false,
      removeModal: false,
      editStop: false,
    })
  }

  saveAnchor = (data) => {
    this.props.dispatch(runActions.editAnchor(data))
  }

  handleChange = (type, e) => {
    e.preventDefault()
    if (type === 'newPosition') {
      //this.props.changePosFn(idElement, newPosition, inStop, child_address_id)
      if (e.target.value) this.props.changePosFn(this.state.id, e.target.value)
    }
    this.setState({
      [type]: e.target.value,
    })
  }

  handleAnchor = () => {
    return !this.props.isRoleDispatcher
      ? this.setState({
          showModalAnchor: true,
        })
      : undefined
  }

  removeRun = (e, id) => {
    this.setState({
      removeModal: true,
    })
  }

  handleStop = () => {
    this.setState({
      editStop: true,
    })
  }

  editToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  deletePoint = () => {
    this.props.delFn()
    this.closeModal()
  }

  render() {
    let { id, address, object, routePointRoute, routeId, lastIndex } =
      this.state
    let school = object
    let { routeWaittime, pointOsrmLeg, route } = this.props
    if (!school) return false
    let newPosition = this.props.routePointRoute.view_position

    let arriveTime = null
    let distance = null
    let duration = null
    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)

      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }
    return (
      <div key={id} className="ListDiv">
        <div data-testid="depotPoint" className="BusDepot bgTranscent deport ">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="buttonTrigger"
            onClick={(e) => this.editToggle(e)}
          >
            <RightIcon
              style={{ color: 'white' }}
              className={classnames(styles.arrow, {
                [styles.active]: this.state.edit,
              })}
            />
          </div>

          {!this.props.isWriteRoutes && (
            <MoreBlock
              isLast={newPosition === lastIndex}
              routePointRoute={routePointRoute}
              id={id}
              handleAnchor={this.handleAnchor}
              handleEditStop={this.handleStop}
              removeRun={this.removeRun}
              route={route}
            />
          )}

          <div className="Head">
            <div className="big">
              {newPosition}. {object.name}
            </div>

            {distance && duration && (
              <div className="normal distance">
                <span>{distance}</span>
                <span>{duration}</span>
              </div>
            )}
          </div>

          {anchorAndTimeFN.bind(this)()}
          <div className={styles.attendanceGroup}>
            <AttendanceGroup routePointRoute={routePointRoute} />
          </div>

          {this.state.edit && (
            <div className="firstLine">
              <div className="flex">
                {address && address.address && (
                  <div className="depBlock">
                    <span className="depAddress">
                      <Trans>Address</Trans>
                    </span>
                    <b className="AddressLatLon">
                      {address.address
                        ? address.address
                        : address.lat + ' ' + address.lon}
                    </b>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.removeModal && (
            <Modal
              closeFn={this.closeModal}
              title={i18n.t('Delete Bus Depot')}
              deleteBtn={i18n.t('Delete Bus Depot')}
              deleteObject={this.state.object.name}
              deleteFn={() =>
                this.deletePoint(
                  id,
                  this.state.position,
                  object.id,
                  this.state.address.id,
                  this.state.type
                )
              }
            />
          )}
          {this.state.editStop && (
            <AllPurpose
              id={id}
              positon={newPosition}
              waittime={routeWaittime ? routeWaittime : 0}
              routeId={routeId}
              select={this.obj.select}
              minute={this.obj.minute}
              stops={this.obj.stops}
              title={i18n.t('Edit Bus Depot Stop')}
              closeFn={this.closeModal}
              depot={object}
            />
          )}

          {this.state.showModalAnchor && (
            <ModalAnchor
              data={{
                id: id,
                routeId: routePointRoute['route_id'],
                name: object.name,
                type: i18n.t('Bus Depot'),
                position: newPosition,
                address: object.address,
                time: routePointRoute.time,
                waittime: routePointRoute.waittime,
                timeZone: this.props.timeZone,
              }}
              saveFn={this.saveAnchor}
              closeFn={this.closeModal}
            />
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    miles: state.routesView.Miles,
    Wait: state.routesView.Wait,
    Arrive: state.routesView.Arrive,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusDepot)
