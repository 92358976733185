import { createApi } from '@reduxjs/toolkit/dist/query/react'
import api from '@/redux/apiConfig'
import { companyUpdate } from '@/redux/auth/actions'
import { notification } from 'antd'
import { useModalStore } from '@/store/modal/ModalStore'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'

import {
  ICloneRunParams,
  ICommentMessage,
  IImportMessage,
  IRegistrationItem,
  IRegistrationSearchParams,
  IRequestResponse,
} from '@/store/routeTool/routeTool.types'

import axios from 'axios'
import {
  baseSetting,
  useImportRidersStore,
} from '@/entities/modals/RidersImportModal/RidersImportModal'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import { store } from '@/redux'
import { ImportResponse } from '@/entities/modals/RidersImportModal/RidersImportModal.types'
import { IOptimizeRunData } from '@/entities/modals/OptimizeRunModal/OptimizeRunModal.types'
import {
  useImportSocketResponse,
  useSocketConnectStore,
} from '@/features/containers/SocketConnect/store'
import * as commonTypes from '@/redux/common/actionTypes'
import * as types from '@/redux/routesView/actionTypes'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'
import _ from 'lodash'
import { catchNotification } from '@/helpers/catchNotification'
import { RUN_EDIT_POINT_FLAG_NAME } from '@/redux/routesView/actionTypes'

export const routeToolApi = createApi({
  reducerPath: 'routeToolApi',
  baseQuery: axiosBaseQuery(api.common),
  endpoints: (build) => ({
    sendEmail: build.query<any, any>({
      query: (data: any) => {
        return {
          method: 'post',
          url: 'children/send-parent-email-by-template?',
          data,
        }
      },
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()

          notification.success({
            message: 'Email has been sent',
            description: 'Parent emails has been placed in queue.',
          })
        } catch (e) {
          notification.error({
            message: 'The email could not be sent. Try again.',
          })
        }
      },
    }),

    changeFlagGroup: build.mutation<
      any,
      {
        flagName: string
        ids: number[]
      }
    >({
      query: ({ flagName, ids }) => {
        return {
          url: '../api/v2/route/point/set-group-flag',
          method: 'post',
          data: {
            routePointRouteIds: ids,
            flagName: flagName,
          },
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
        } catch (e) {
          notification.error({
            message: 'An error occurred while setting the group flag',
          })
        }
      },
    }),
    updateEmail: build.query<any, any>({
      query: (data) => {
        return {
          url: '/companies/update',
          method: 'put',
          data,
        }
      },
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          dispatch(companyUpdate(response.data))
        } catch (e) {
          notification.error({
            message: 'The email could not be sent. Try again.',
          })
        }
      },
    }),
    sendParentEmail: build.query({
      query: (data) => {
        return {
          url: 'children/send-parent-email',
          method: 'post',
          data,
        }
      },
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
          notification.success({
            message: 'Email has been sent',
            description: 'Parent emails has been placed in queue.',
          })
        } catch (e) {
          notification.error({
            message: 'The email could not be sent. Try again.',
          })
        }
      },
    }),
    importChildren: build.query<{ data: ImportResponse }, void>({
      query: () => {
        const formData = new FormData()
        const { escape, enclosure, fileList, delimiter } =
          useImportRidersStore.getState()

        formData.append('ImportForm[file]', fileList[0])
        formData.append('ImportForm[delimiter]', delimiter)
        formData.append('ImportForm[enclosure]', enclosure)
        formData.append('ImportForm[escape]', escape)

        return {
          url: axios.defaults.baseURL + '/children/import',
          method: 'post',
          data: formData,
        }
      },
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { fileList, ...rest } = baseSetting
          useImportRidersStore.getState().setImportRidersStore({
            ...({
              ...rest,
              importResponse: {
                ...response.data.data,
                ...transformResponse(response.data.data),
              },
            } as any),
            step: 'mapping',
          })
        } catch (e) {
          notification.error({ message: 'Failed to start import. Try again.' })
        }
      },
    }),
    addFile: build.query<
      string,
      { data: Record<string, any>; sessionId?: number }
    >({
      query: ({ data, sessionId }) => {
        useImportSocketResponse.getState().reset()

        const season_id = store.getState().sessions.activeSeason.id

        return {
          url: getUrlWithSearchParams(
            api.childImport.defaults.baseURL + '/importlines',
            { season_id: sessionId ?? season_id }
          ),
          data,
          method: 'post',
        }
      },
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response: any = await queryFulfilled

          useImportSocketResponse
            .getState()
            .setStore({ uidImport: response.data?.data?.uidImport ?? '' })
          useImportRidersStore.getState().setImportRidersStore({
            step: 'process',
          })
        } catch (e) {
          notification.error({ message: 'Failed to start import. Try again.' })
        }
      },
    }),

    getOpenedParentRequests: build.query<IRequestResponse, any>({
      query: (params) => {
        return {
          url: getUrlWithSearchParams(
            api.gpsV2.defaults.baseURL + '/parent-requests/opened',
            params
          ),
        }
      },
      keepUnusedDataFor: 0,
    }),
    getClosedParentRequests: build.query<IRequestResponse, any>({
      query: (params) => {
        return {
          url: getUrlWithSearchParams(
            api.gpsV2.defaults.baseURL + '/parent-requests/closed',
            params
          ),
        }
      },
      keepUnusedDataFor: 0,
    }),

    getRTRequestsMessage: build.query<{ data: ICommentMessage[] }, string>({
      query: (id) => {
        return {
          url: `${api.gpsV2.defaults.baseURL}/parent-requests/messages/index/${id}`,
        }
      },
      keepUnusedDataFor: 0,
    }),
    postRejectRequest: build.query<any, string>({
      query: (id) => {
        return {
          method: 'post',
          url: `${api.gpsV2.defaults.baseURL}/parent-requests/reject/${id}`,
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
          notification.success({
            message: 'Rejection message sent successfully',
          })
        } catch (e) {
          notification.error({
            message: 'Rejection message could not be sent',
          })
        }
      },
    }),

    postRejectMessageRequest: build.query<any, { id: number; message: string }>(
      {
        query: ({ id, message }) => {
          return {
            method: 'post',
            data: { requestId: id, text: message },
            url: `${api.gpsV2.defaults.baseURL}/parent-requests/messages/send`,
          }
        },
        keepUnusedDataFor: 0,
        onQueryStarted: async (_, { queryFulfilled }) => {
          try {
            await queryFulfilled
          } catch (e) {
            notification.error({
              message: 'Error',
            })
          }
        },
      }
    ),
    postManualAccept: build.query<any, number>({
      query: (id) => {
        return {
          method: 'post',
          url: `${api.gpsV2.defaults.baseURL}/parent-requests/accept-manual/${id}`,
        }
      },
      keepUnusedDataFor: 0,

      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
          notification.success({
            message: "Guardian's request successfully accepted",
          })
        } catch (e) {
          notification.error({
            message: "The guardian's request could not be accepted",
          })
        }
      },
    }),
    postAutoAccept: build.query<
      any,
      { id: number; actions: any; schoolAmId?: number; schoolPmId: number }
    >({
      query: ({ id, actions = [], ...rest }) => {
        return {
          method: 'post',
          url: `${api.gpsV2.defaults.baseURL}/parent-requests/accept-auto/${id}`,
          data: { actions, ...rest },
        }
      },
      keepUnusedDataFor: 0,

      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
          notification.success({
            message: "Guardian's request successfully accepted",
          })
        } catch (e) {
          notification.error({
            message:
              typeof e?.error?.data?.message === 'string'
                ? e?.error?.data?.message
                : "The guardian's request could not be accepted",
          })
        }
      },
    }),
    getImportInfo: build.query<{ data: IImportMessage }, string>({
      query: (seasonId) => ({
        url: getUrlWithSearchParams(
          `${api.childImport.defaults.baseURL}/importmessage`,
          { seasonId }
        ),
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          response?.data?.data?.ignored.forEach((el) => {
            const data = {
              status: 5,
              statusMessage: 'count_ignored',
              uidImport: '',
              meta: {
                data: {},
                errors: { test: [el] },
              },
            }
            useImportSocketResponse.getState().setData(data as any)
          })
          useImportRidersStore.getState().setImportRidersStore({
            total: response.data?.data.total,
            step: 'process',
          })
        } catch (e) {
          //
        }
      },
    }),
    importCancel: build.query<any, string>({
      query: (seasonId: string) => ({
        url: getUrlWithSearchParams(
          `${api.childImport.defaults.baseURL}/importcancel`,
          { seasonId }
        ),
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
        } catch (e) {
          //
        }
      },
    }),

    //riders
    getRiders: build.query({
      query: ({ page, season_id }) => {
        return {
          url: getUrlWithSearchParams('/children', {
            page,
            season_id,
            expand: 'addresses.address,addresses.route',
          }),
        }
      },
    }),

    registrationOpen: build.query<any, number>({
      query: (requestId) => {
        return {
          method: 'post',
          url: getUrlWithSearchParams(
            `../api/v2/parent-requests/registration/open/${requestId}`
          ),
        }
      },
    }),

    getRegistrationsOpened: build.query<
      { data: IRegistrationItem[] },
      IRegistrationSearchParams
    >({
      query: (params) => {
        return {
          method: 'get',
          url: getUrlWithSearchParams(
            '../api/v2/parent-requests/registration/list',
            params,
            {}
          ),
        }
      },
      keepUnusedDataFor: 90,
    }),
    rejectRegistrationRequest: build.query<
      void,
      { id: number; message: string }
    >({
      query: ({ id, message }) => {
        return {
          url: `../api/v2/parent-requests/registration/reject/${id}`,
          method: 'post',
          data: { message },
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
        } catch (e) {
          useModalStore.getState().onCloseModal()
        }
      },
    }),

    manualAcceptRegistrationRequest: build.query<
      void,
      { id: number; message: string }
    >({
      query: ({ id, message }) => {
        return {
          url: `../api/v2/parent-requests/registration/accept-manual/${id}`,
          method: 'post',
          data: { message },
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
        } catch (e) {
          useModalStore.getState().onCloseModal()
        }
      },
    }),

    autoAcceptRegistrationRequest: build.query<void, number>({
      query: (id) => {
        return {
          url: `../api/v2/parent-requests/registration/approve/${id}`,
          method: 'post',
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
        } catch (e) {
          useModalStore.getState().onCloseModal()
        }
      },
    }),

    cloneRun: build.mutation<{ data: any }, ICloneRunParams>({
      query: (data) => ({
        url: '/routes/copy',
        method: 'post',
        data: {
          ...data,
          expand:
            'points.routePointChildren.childaddress,points.routePointRoute,points,riderscount,stopscount',
        },
      }),
      onQueryStarted: async ({ id }, { queryFulfilled, dispatch }) => {
        dispatch({ type: commonTypes.PENDING_START })

        try {
          const response = await queryFulfilled

          dispatch({
            type: types.ROUTE_ADD_ITEM,
            payload: _.cloneDeep(response.data.data),
            runId: id,
          })
          closeModal()
        } catch (e) {
          catchNotification(e)
        } finally {
          dispatch({ type: commonTypes.PENDING_END })
        }
      },
    }),

    optimizeRun: build.query<any, IOptimizeRunData>({
      query: (data) => {
        return {
          method: 'post',
          url: '../api/v2/route/optimize',
          data,
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          useSocketConnectStore.getState().setOptimize({ isLoading: true })

          await queryFulfilled
        } catch (e) {
          useSocketConnectStore.getState().setOptimize({ isLoading: false })
          notification.error({ message: 'Server error' })
        }
      },
    }),
  }),
})

export const {
  useLazyAutoAcceptRegistrationRequestQuery,
  useLazyManualAcceptRegistrationRequestQuery,
  useLazyRejectRegistrationRequestQuery,
  useLazyGetRegistrationsOpenedQuery,
  useLazyOptimizeRunQuery,
  useLazySendEmailQuery,
  useLazyRegistrationOpenQuery,
  useLazyUpdateEmailQuery,
  useLazySendParentEmailQuery,
  useLazyGetOpenedParentRequestsQuery,
  useLazyGetClosedParentRequestsQuery,
  useGetRTRequestsMessageQuery,
  useLazyPostRejectRequestQuery,
  useLazyPostRejectMessageRequestQuery,
  useLazyPostManualAcceptQuery,
  useLazyPostAutoAcceptQuery,
  useLazyImportChildrenQuery,
  useLazyAddFileQuery,
  useLazyImportCancelQuery,
  useGetRidersQuery,
  useCloneRunMutation,
  useChangeFlagGroupMutation,
} = routeToolApi

const transformResponse = (data: any) => {
  if (Array.isArray(data.headers)) {
    return {
      mappingForm: data.mappingForm,
      headers: data.headers,
    }
  } else {
    const headers = data?.headers
    const mappingForm = data?.mappingForm
    const _newMappingForm = {}
    const newMappingForm = {}
    Object.values(mappingForm)
      .filter((v) => !!v)
      .forEach((value: string) => {
        _newMappingForm[value] = value ? headers?.[value] || null : null
        //
      })

    Object.keys(mappingForm).forEach((key) => {
      const currentKey = mappingForm[key]
      newMappingForm[key] = currentKey ? _newMappingForm[currentKey] : null
    })

    return {
      mappingForm: newMappingForm,
      headers: Object.values(data.headers),
      xlsxDefault: data,
    }
  }
}
