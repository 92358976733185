import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Rules from '../../../../../components/Rules/index'

import { compact, orderBy, uniqBy } from 'lodash'
import PointInBuilder from './../PointInBuilder/index'
import { Button, Popover } from 'antd'

import * as fieldTripRequests from '../../../../../redux/fieldTrip/requests/actions'
import { Bus, Driver, FieldTripRun } from '../../models'
import {
  totalDist,
  totalTimeWithWT,
} from '../../../../../components/RepeatFunctions/timeWork'
import { Select } from 'antd'
import { SuffixIcon } from '../../../../../img/iconsComponents/SuffixIcon'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { NewAntInput } from '../../../../../entities/ui/NewInput/NewAntInput'

class PropsModel {
  /** @type {FieldTripRun} */
  run

  /** @type {Bus[]} */
  buses

  /** @type {Driver[]} */
  drivers

  /** @type {boolean} */
  isReadonly

  // /** @type {{ [key: string]: (...args?) => any }} */
  functions
}

/**
 * @typedef {PropsModel} PropsType
 * @extends {Component<PropsType>}
 */
class TabFieldTripRun extends Component {
  /**
   *
   * @param {PropsType} props
   */
  constructor(props) {
    super(props)
    this.state = {
      run: props.run,
      functions: props.functions,
      buses: props.buses,
      drivers: props.drivers,
      isEditingName: false,
      isEditName: false,
      newRunName: '',
      isReadonly: props.isReadonly,
      dndLoad: false,
      visible: false,
      runNameLength: false,
      emptyRunName: false,
    }
  }

  /**
   *
   * @param {Readonly<PropsType>} nextProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.dndLoad !== prevProps.dndLoad) {
      this.setState({
        dndLoad: false,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      run: nextProps.run,
      isReadonly: nextProps.isReadonly,
    })

    if (nextProps.run.dndLoad === false) {
      this.setState({
        dndLoad: false,
      })
    }
  }

  editRunName = () => {
    console.log('click')
    if (this.state.isReadonly) {
      return
    }

    this.setState({
      isEditName: true,
    })
  }

  handleRunNameChange = (name) => {
    this.setState({
      newRunName: name,
      runNameLength: name.length > 64,
      emptyRunName: name === '',
    })
  }

  editRules = (id, name, value) => {
    this.props.functions.editRules(id, name, value)
  }

  saveRunName = (id, name) => {
    const { runNameLength, emptyRunName } = this.state
    if (runNameLength || emptyRunName) return
    this.setState({
      isEditName: false,
    })

    this.props.functions.saveRunName(id, name)
  }

  CancelRunName = () => {
    this.setState({
      isEditingName: false,
    })
  }

  reversePoints = () => {
    let { run } = this.state

    if (!run.fieldTripPoints) {
      return
    }

    this.state.functions.reversePoints(run.id)
  }

  getPointOsrmLeg = (osrm, index) => {
    if (osrm && osrm !== 'ERROR' && osrm.legs) {
      if (index === osrm.legs.length) {
        return osrm.legs[index - 1].end_point
      } else {
        return osrm.legs[index].start_point
      }
    }
  }

  renderPoints(isReadonly) {
    let { run } = this.state
    let osrm = run.osrm
    if (!run.fieldTripPoints) {
      return
    }

    let points = uniqBy(
      orderBy(run.fieldTripPoints, ['position'], ['asc']),
      'id'
    )
    return points.map((point, index) => {
      let pointOsrmLeg = this.getPointOsrmLeg(osrm, index)
      return (
        <Draggable
          isDragDisabled={isReadonly}
          key={point.id}
          draggableId={point.id.toString()}
          index={index}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <PointInBuilder
                key={point.id}
                childrenList={this.props.childrenList}
                index={index}
                point={point}
                pointOsrmLeg={pointOsrmLeg}
                toggleModalEditFn={this.props.functions.toggleModalEdit}
                toggleModalAnchorFn={this.props.functions.toggleModalAnchor}
                toggleModalDeletePointFn={
                  this.props.functions.toggleModalDeletePoint
                }
                deletePointFn={this.props.functions.deletePoint}
                removeRiderFn={this.props.functions.removeRider}
                handleSetTitle={this.props.handleSetTitle}
                deleteRiderFn={this.props.functions.deleteRider}
                isReadonly={this.props.isReadonly}
                isLast={index === points.length - 1}
                positonExp={index}
                activeTrip={this.props.activeTripId}
                activeRun={this.props.activeRunId}
              />
            </div>
          )}
        </Draggable>
      )
    })
  }

  get ridersCount() {
    let ridersChildren = new Set()
    let ridersAdult = new Set()

    if (!this.state.run.fieldTripPoints) {
      return 0
    }

    this.state.run.fieldTripPoints.forEach((point) => {
      if (point.fieldTripRiders) {
        point.fieldTripRiders.forEach((rider) => {
          ridersChildren.add(rider)
        })
      }

      if (point.fieldTripRiderAdults) {
        point.fieldTripRiderAdults.forEach((rider) => {
          ridersAdult.add(rider)
        })
      }
    })

    return ridersChildren.size + ridersAdult.size
  }

  hide = () => {
    this.setState({
      visible: false,
    })
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible })
  }

  render() {
    let { run, functions, buses, drivers, osrm } = this.props
    return (
      <div>
        <div className="activeInfo">
          <div className={'activeInfoBlock'}>
            <div className="block riders">
              <span>Riders</span>
              <span data-testid="assignedRidersCount">{this.ridersCount}</span>
            </div>

            <div className="block stop">
              <span>Stops</span>
              <span data-testid="stopCountInRun">
                {run.fieldTripPoints ? run.fieldTripPoints.length : 0}
              </span>
            </div>

            <div className="block time">
              <span>Total time</span>
              <span className="TotalTime">
                {osrm.hasOwnProperty('duration') &&
                run.fieldTripPoints.length > 1
                  ? totalTimeWithWT(osrm, run.fieldTripPoints)
                  : '0 min'}
              </span>
            </div>

            <div className="block dist">
              <span>Total dist</span>
              <span>
                {osrm.hasOwnProperty('distance') &&
                run.fieldTripPoints.length > 1
                  ? totalDist(osrm.distance)
                  : '0 mi'}
              </span>
            </div>
          </div>

          {!this.state.isReadonly && (
            <div className={'FTRunBuilder_btn'}>
              <Button
                data-testid="addSchoolBtn"
                className="schoolBtn"
                onClick={() => functions.toggleModal('modalSchool')}
              >
                + Add School
              </Button>
              <Button
                data-testid="addStopBtn"
                className="stopBtn"
                onClick={() => functions.toggleModal('modalStop')}
              >
                + Add Stop
              </Button>
              <Button
                data-testid="addDepotBtn"
                className="depotBtn"
                onClick={() => functions.toggleModal('modalDepot')}
              >
                + Add Depot
              </Button>
              <Button
                data-testid="riderBtn"
                className="riderBtn"
                onClick={() => functions.toggleModal('modalRider')}
              >
                + Additional Rider
              </Button>
            </div>
          )}
        </div>

        <div className="run-info">
          <div
            className="head-line"
            style={{ marginBottom: 0, justifyContent: 'flex-start', gap: 4 }}
          >
            {!this.state.isEditName && (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
                className={run.name.length > 18 ? 'run-name' : 'run-name hide'}
              >
                <span>{run.name}</span>
                {!this.state.isReadonly && !this.state.isEditName && (
                  <div onClick={this.editRunName}>
                    <span className={'iconPencil'} />
                  </div>
                )}
              </div>
            )}
            {this.state.isEditName && (
              <div className="run-name editInp">
                <span style={{ width: '100%', marginRight: '10px' }}>
                  <NewAntInput
                    className={
                      this.state.emptyRunName || this.state.runNameLength
                        ? 'errorBorder'
                        : ''
                    }
                    onChange={(e) => this.handleRunNameChange(e.target.value)}
                    defaultValue={run.name}
                  />
                </span>

                <span
                  onClick={() =>
                    this.saveRunName(run.id, this.state.newRunName)
                  }
                >
                  <CheckOutlined />
                </span>

                <span onClick={() => this.setState({ isEditName: false })}>
                  <CloseOutlined />
                </span>
                <p className={'errorSpanFTRun'}>
                  {this.state.emptyRunName
                    ? 'Run name can not be empty'
                    : this.state.runNameLength
                    ? 'Run name should contain at most 64 characters'
                    : ''}
                </p>
              </div>
            )}
          </div>

          {!this.state.isReadonly && (
            <div
              className={
                run.name.length <= 18
                  ? 'head-line withRun'
                  : 'head-line NowithRun'
              }
            >
              {run.name.length <= 18 ? (
                <div className="run-name">
                  {!this.state.isReadonly && !this.state.isEditName && (
                    <div>
                      <span>{run.name}</span>
                      <span onClick={this.editRunName}>
                        <span className={'iconPencil'} />
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
              <div className="run-buttons">
                <Button
                  className="copy"
                  onClick={() => functions.printRun(run.id)}
                >
                  <span className="copyIcon" />
                  Print
                </Button>
                <Popover
                  content={
                    <Rules
                      // style={{ top: 0, left: 0 }}
                      fn={this.editRules.bind(this)}
                      selectedTrip={this.state.selectedTrip}
                      route={run}
                      company={this.props.company}
                    />
                  }
                  // title={null}
                  trigger="click"
                  placement="bottom"
                  // visible={this.state.visible}
                  // onVisibleChange={this.handleVisibleChange}
                >
                  <Button className="rules" disabled={this.state.isReadonly}>
                    <span className="settingsIcon" />
                    Rules
                  </Button>
                </Popover>

                <Button
                  className="copy"
                  disabled={this.state.isReadonly}
                  onClick={() => functions.copyRun(run.id)}
                >
                  <span className="copyIcon" />
                  Copy
                </Button>
                <Button
                  className="reverse"
                  disabled={this.state.isReadonly}
                  onClick={this.reversePoints}
                >
                  <span className="reverseIcon" />
                  Reverse
                </Button>
                <Button
                  className="delete "
                  disabled={this.state.isReadonly}
                  onClick={() => functions.deleteRun(null, run.id)}
                >
                  <span className="deleteIcon noBorder" />
                  Delete
                </Button>
              </div>
            </div>
          )}

          {!this.state.isReadonly && (
            <div className="input-wrapper">
              <Select
                disabled={this.state.isReadonly}
                onChange={(e) => functions.changeBus(run.id, e)}
                className="select"
                placeholder={'Select bus'}
                value={run.bus_id || undefined}
                suffixIcon={
                  <SuffixIcon
                    style={{ top: '2px', position: 'relative', right: '-6px' }}
                  />
                }
              >
                {buses.map((bus) => (
                  <Select.Option key={bus.id} value={bus.id}>
                    {bus.bus_number}{' '}
                    {bus.capacity && (
                      <span style={{ color: `#8d8d8d` }}>
                        (Capacity {bus.capacity})
                      </span>
                    )}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}

          {this.state.isReadonly && (
            <div className="input-wrapper">
              <NewAntInput
                readOnly
                className="select"
                value={run.bus && run.bus.bus_number}
              />
            </div>
          )}

          {!this.state.isReadonly && (
            <div className="input-wrapper">
              <Select
                disabled={this.state.isReadonly}
                onChange={(e) => functions.changeDriver(run.id, e)}
                className="select"
                placeholder={'Select driver'}
                value={run.driver_id || undefined}
                suffixIcon={
                  <SuffixIcon
                    style={{ top: '2px', position: 'relative', right: '-6px' }}
                  />
                }
              >
                {drivers.map((driver) => (
                  <Select.Option key={driver.id} value={driver.id}>
                    {driver.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}

          {this.state.isReadonly && (
            <div className="input-wrapper">
              <NewAntInput
                readOnly
                className="select"
                value={run.driver.name}
              />
            </div>
          )}
        </div>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className={'noSelect'}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.renderPoints(this.state.isReadonly)}
                {provided.placeholder}

                <div
                  className={`preLoad ${this.state.dndLoad ? 'active' : ''}`}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }

  onDragEnd = (end) => {
    let { fieldTripPoints, id } = this.state.run
    let newObj = fieldTripPoints[end.source.index]

    const newFieldTripPoints = [...fieldTripPoints]

    if (end.source.index === end.destination.index) return
    newFieldTripPoints.forEach((item, index) => {
      item.position = index + 1
    })

    let newFTP = [...fieldTripPoints]

    let source = end.source.index
    let destination = end.destination.index

    newFTP = newFTP.map((x) => {
      if (x.id === end.draggableId) {
        return false
      }
      if (x.position >= source && x.position < destination) {
        x.position += 1
        return x
      } else if (x.position < destination) {
        x.position -= 1
        return x
      } else if (x.position === destination) {
        x.position += 1
        return x
      }

      if (x.position > destination && x.position <= source) {
        x.position += 1
        return x
      } else if (x.position > source) {
        x.position -= 1
        return x
      }
    })
    newFTP = compact(newFTP)
    newFTP.push(newObj)
    newFTP = uniqBy(orderBy(newFTP, ['position'], ['asc']), 'id')

    const newRun = { ...this.state.run, fieldTripPoints: newFieldTripPoints }
    newRun.fieldTripPoints = newFTP

    this.setState({
      run: newRun,
      dndLoad: true,
    })

    delete newObj.address

    this.props.dispatch(
      fieldTripRequests.editPoint(newObj, this.props.selectedTrip, id)
    )
  }
}

function mapStateToProps(state) {
  return { company: state.auth }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabFieldTripRun)
