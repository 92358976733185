import { Popover } from 'antd'
import styles from './SelectWithCountCheckbox.module.less'
import { NewCheckbox } from '@/entities/ui/NewCheckbox/NewCheckbox'
import { Fragment, ReactNode, useState } from 'react'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { SuffixIcon } from '@/img/iconsComponents/SuffixIcon'

import classnames from 'classnames'
import { NewButton } from '@/entities/ui/NewButton/NewButton'

export interface IItem {
  label: ReactNode
  callback: () => void
  show?: boolean
  id?: string
}
interface IProps {
  onCheckAll: (e: CheckboxChangeEvent) => void
  count: number
  disabled: boolean
  items: IItem[]
}
export const SelectWithCountCheckbox = ({
  onCheckAll,
  count = 0,
  disabled,
  items = [],
}: IProps) => {
  const [open, setOpen] = useState(false)
  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      content={
        <div
          onClick={(e) => !disabled && e.stopPropagation()}
          className={styles.content}
        >
          {items.map(({ show = true, callback, label, id }) => (
            <Fragment key={id}>
              {show ? (
                <NewButton
                  onClick={() => {
                    callback?.()
                    setOpen(false)
                  }}
                  type="text"
                  className={styles.button}
                >
                  {label}
                </NewButton>
              ) : null}
            </Fragment>
          ))}
        </div>
      }
    >
      <div
        onClick={() => !disabled && setOpen((prev) => !prev)}
        className={classnames(
          { [styles.previewOpen]: open },
          { [styles.disabled]: disabled },
          styles.preview
        )}
      >
        <NewCheckbox
          disabled={disabled}
          onChange={onCheckAll}
          onClick={(e) => e.stopPropagation()}
        />
        <span>{count}</span>
        <SuffixIcon
          className={classnames(styles.icon, { [styles.open]: open })}
        />
      </div>
    </Popover>
  )
}
