import React, { useState, useEffect, useRef } from 'react'
import './index.less'
import ReactDOM from 'react-dom'
import RunModalAdd from './../../../BuildRun/components/RunModalAdd/index'
import i18n from '../../../../../../i18n'
import { useMap } from '../../../../../../components/MapDefault/MapContext'
import { useSelector } from 'react-redux'
import { getLoaderState } from '../../../../../../redux/common/selectors'
import { getCompanyPermissions } from '../../../../../../redux/auth/selectors'
import { useRulerStore } from '@/components/MapDefault/ruler/rulerStore'

export default function (props) {
  const {
    mapType,
    setMapType,
    zoomOut,
    zoomIn,
    setDrawTool,
    drawTool,
    ...rest
  } = useMap()
  const [openModalAdd, setOpen] = useState(false)
  const [zoneOpen, setZoneOpen] = useState(false)
  const [selectedTool, setSelectedTool] = useState(null)
  const [activeShape, setActiveShape] = useState(false)

  const refElementsScope = useRef()

  const permissions = useSelector(getCompanyPermissions)
  const loaderState = useSelector(getLoaderState)
  const [isLoading, setLoading] = useState(false)
  const isLoadingStart = loaderState === 'loadingStart'

  useEffect(() => {
    setLoading(isLoadingStart)
  }, [loaderState, isLoading])

  useEffect(() => {
    let handleCM = () => {
      return refElementsScope.current.addEventListener('contextmenu', (e) => {
        e.preventDefault()
        e.stopPropagation()
      })
    }
    handleCM()
    return () =>
      refElementsScope.current.removeEventListener('contextmenu', handleCM)
  }, [])

  useEffect(() => {
    if (props.activeRun) setActiveShape(true)
  }, [props.activeRun])

  useEffect(() => {
    if (!drawTool) setSelectedTool('')
  }, [drawTool])

  const runModalAdd = () => {
    setOpen((prev) => !prev)
  }

  const switchMapType = () => {
    let typeSwitch = 'roadmap'
    if (mapType === 'roadmap') typeSwitch = 'traffic'
    if (mapType === 'traffic') typeSwitch = 'satellite'
    if (mapType === 'satellite') typeSwitch = 'roadmap'
    setMapType(typeSwitch)
  }

  // radius oval
  const activeEl = (type) => {
    let typeActive

    if (!activeShape && type !== 'polyline') return

    if (type === 'polygon' || type === 'polyline') {
      if (type === 'polygon') {
        typeActive = 'free-form'
      }

      if (type === 'polyline') {
        typeActive = 'ruler'
      }
    } else {
      typeActive = type
    }

    setSelectedTool(typeActive)
    setDrawTool(type)
    setZoneOpen(false)
  }

  useEffect(() => {
    if (selectedTool !== 'ruler') {
      const isRuler = useRulerStore.getState().isRule
      isRuler && useRulerStore.getState().setStore({ isRule: false })
    }
  }, [selectedTool])

  const renderDropDown = () => {
    document.querySelector('.zoneMenu').className = `zoneMenu ${
      !zoneOpen ? 'hide' : ''
    }`

    return ReactDOM.createPortal(
      <div className="body">
        <div
          className={`ruler ${selectedTool === 'polyline' ? 'active' : ''}`}
          onClick={() => {
            useRulerStore.getState().setStore({ isRule: true })
            activeEl('polyline')
          }}
        >
          {i18n.t('Ruler')}
        </div>

        <div
          className={`rectangle 
            ${selectedTool === 'rectangle' ? 'active' : ''} 
            ${!activeShape ? 'blockButton' : ''}
          `}
          onClick={() => activeEl('rectangle')}
        >
          {i18n.t('Rectangle')}
        </div>
        <div
          className={`free-form 
            ${selectedTool === 'free-form' ? 'active' : ''}
            ${!activeShape ? 'blockButton' : ''} 
          `}
          onClick={() => activeEl('polygon')}
        >
          {i18n.t('Free Form')}
        </div>
        <div
          className={`oval 
            ${selectedTool === 'oval' ? 'active' : ''}
            ${!activeShape ? 'blockButton' : ''}
          `}
          onClick={() => activeEl('oval')}
        >
          {i18n.t('Oval')}
        </div>

        <div
          className={`radius 
            ${selectedTool === 'radius' ? 'active' : ''}
            ${!activeShape ? 'blockButton' : ''}
          `}
          onClick={() => activeEl('radius')}
        >
          {i18n.t('Radius')}
        </div>
      </div>,
      document.querySelector('.zoneMenu')
    )
  }

  let className = `zone ${zoneOpen ? 'open' : ''} ${selectedTool}`

  return (
    <div ref={refElementsScope} className="Zones mapButtomControl">
      <div className="zone_selection hide">Zones</div>
      {renderDropDown()}
      {permissions?.['write_routes'] && (
        <div
          onClick={() => {
            if (!isLoading) {
              setZoneOpen(!zoneOpen)
            }
          }}
          className={className}
          style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
        />
      )}

      <div
        className={mapType === 'roadmap' ? 'earth' : 'road'}
        onClick={switchMapType}
      />

      <div className="zoomOut" onClick={zoomOut} />
      <div className="zoomIn" onClick={zoomIn} />
      <div className="target" onClick={props.target} />
      <div
        data-testid="addNewRun"
        className={`btnNewRan ${
          props.isWriteRoutes ? 'btnNewRunDisabled' : ''
        }`}
        onClick={!props.isWriteRoutes && runModalAdd}
      >
        <span>{i18n.t('+ Add New Run')}</span>
      </div>

      {openModalAdd && <RunModalAdd id={'NoPadd'} closeFn={runModalAdd} />}
    </div>
  )
}
