import { getIconBus } from '@/components/RepeatFunctions/mapGenerate'
import {
  handleMouseOut,
  handleMouseOver,
} from '@/pages/Gps/NewGpsDetails/helpers/common'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Marker } from 'react-leaflet'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { MqttClient } from 'mqtt'
import moment from 'moment/moment'
import { LatLngTuple } from 'leaflet'
import ReactLeafletDriftMarker from 'react-leaflet-drift-marker'
import PolylineTextGradient, {
  AnimatedPolyline,
} from '@/components/MapDefault/src/polyline-text/PolylineTextGpsColor'
import { RunGeometry } from '@/pages/Gps/NewGpsDetails/components/RunGeometry'

type BusPositionProps = {
  mqttClient: MqttClient
  timestamp: number
}

export const BusPosition: FC<BusPositionProps> = ({
  mqttClient,
  timestamp,
}) => {
  const {
    gpsInfo: { consoleHash, timeZone, endTime },
    mapRef,
  } = useGpsDetailsStore.getState()

  const [busPosition, setBusPosition] = useState<LatLngTuple | null>(null)
  const [bearingBus, setBearingBus] = useState(0)
  const [canAnimate, setCanAnimate] = useState<boolean>(false)
  const [polyline, setPolyline] = useState<string[] | null>(null)
  const [isLate, setIsLate] = useState<boolean>(false)

  const prevBusPosition = useRef<LatLngTuple | null>(null)

  useEffect(() => {
    prevBusPosition.current = busPosition
  }, [busPosition])

  useEffect(() => {
    mqttClient.on('message', (topic, message) => {
      const msg = JSON.parse(message.toString())
      if (topic === `${consoleHash}_front` || topic === consoleHash) {
        const {
          mLat,
          mLng,
          mBearing,
          mVelocity,
          mDateTime,
          geometry,
          difference,
        } = msg
        setBusPosition([mLat, mLng])
        setBearingBus(mBearing)
        geometry && setPolyline(geometry)
        setIsLate(difference < 0)
        topic === `${consoleHash}_front` && !canAnimate && setCanAnimate(true)
        useGpsDetailsStore.getState().setMqttGpsData({
          timeString: moment(mDateTime, 'DD.MM.YYYY hh:mm:ss').format('HH:mm'),
          bearing: mBearing,
          speed: mVelocity,
        })
        console.log('%c%s', `color: red;'`, `MQTT - data server!!!`, msg)
      }
    })
  }, [consoleHash, canAnimate, timeZone, endTime, mqttClient])

  if (!busPosition || busPosition.length < 2)
    return <RunGeometry timestamp={timestamp} canAnimate={canAnimate} />

  return (
    <>
      {canAnimate ? (
        <ReactLeafletDriftMarker
          position={busPosition}
          duration={1000}
          icon={getIconBus(bearingBus)}
        />
      ) : (
        <Marker
          alt={'Bus'}
          id={'bus'}
          position={busPosition}
          icon={getIconBus(bearingBus)}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      )}

      {canAnimate && (
        <AnimatedPolyline
          map={mapRef.current}
          start={prevBusPosition.current}
          end={busPosition}
          isLate={isLate}
        />
      )}

      {polyline &&
        polyline.map((g) => (
          <PolylineTextGradient
            key={g}
            geometry={g}
            late={isLate}
            nextLate={isLate}
          />
        ))}

      <RunGeometry timestamp={timestamp} canAnimate={canAnimate} />
    </>
  )
}
