import axios from 'axios'
import { localUrlGeo } from '@/redux/configLocal'

export const getOsrm = async (data) => {
  try {
    if (!data || !data.points || data.points.length < 2) {
      throw new Error('Invalid data: at least 2 points required')
    }

    const filteredPoints = data.points.filter((point) => {
      return point.address && point.geo.lat && point.geo.lon
    })

    const points = filteredPoints.map((point) => {
      return {
        lat: point.geo.lat,
        lon: point.geo.lon,
        waittime: point.waittime || 0,
        is_anchor: 0,
        time: null,
        curbsidePickup: 0,
        type: point.stopType,
      }
    })

    const send = { points: points, steps: 'true' }
    const response = await axios.post(localUrlGeo, send)

    if (response.status === 200) {
      return response.data
    } else {
      console.warn('OSRM ERROR')
      throw new Error('OSRM returned an error status')
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
