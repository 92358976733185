import 'react-contexify/dist/ReactContexify.min.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, Marker } from 'react-leaflet'
import './index.less'
import PolylineText from '../../../../../components/MapDefault/src/polyline-text/PolylineText'
import * as fieldTripActions from '../../../../../redux/fieldTrip/requests/actions'
import * as fieldTripRequestsActions from '../../../../../redux/fieldTrip/requests/actions'
import {
  assignedPopup,
  directionsPathOptions,
  getIcon,
  getRouteMarkersFT,
} from './mapSpecialFns'
import _ from 'lodash'
import {
  contextMenuOnDepot,
  fnMouseOut,
  fnMouseOver,
} from '@/components/MapDefault/mapSpecialFns'
import { mapKey } from '@/redux/configLocal'
import ReactLeafletGoogleLayer from '../../../../../components/GoogleLayer/index'

class MapRunBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      osrm: {},
      bounds: [],
      depots: [],
      markers: [],
      showMap: false,
      fieldTripPoints: [],
      zoom: 14,
      isExpanded: {
        riders: false,
        adults: false,
      },
      activeTarget: this.props.activeTarget,
      activeRun: props.activeRun,
      activeRuns: props.activeRuns,
      activeTrip: props.activeTrip,
      fieldTrip: props.fieldTrip,
    }
    this.mapRef = React.createRef()
  }

  handleExpand = (type) => {
    const isExpanded = this.state.isExpanded
    isExpanded[type] = !isExpanded[type]

    this.setState({
      isExpanded: isExpanded,
    })
  }
  removeStopInRun = (object) => {
    this.props.dispatch(
      fieldTripRequestsActions.deletePoint(
        object.id,
        this.state.activeTrip,
        object.run_id
      )
    )
  }

  deleteRider = (stopId, riderId, isAdditional) => {
    let activeRuns = this.state.fieldTrip.data.find(
      (x) => x.id === this.state.activeTrip
    )
    let fieldTripRuns = activeRuns.fieldTrip.fieldTripRuns
    let activeRun = fieldTripRuns.find((x) => x.id === this.state.activeRun)
    let point = activeRun.fieldTripPoints.find((x) => x.id === stopId)
    delete point.address

    if (isAdditional) {
      point.fieldTripRiderAdults = point.fieldTripRiderAdults.filter(
        (rider) => rider.id !== riderId
      )
    } else {
      point.fieldTripRiders = point.fieldTripRiders.filter(
        (rider) => rider.id !== riderId
      )
    }

    this.props.dispatch(
      fieldTripRequestsActions.removeRiders(
        point,
        this.state.activeTrip,
        point.run_id
      )
    )
  }
  childRidersAdditions = (marker) => {
    if (marker.object.fieldTripRiderAdults.length > 0) {
      let data = marker.object.fieldTripRiderAdults.map((rider) => {
        if (rider) {
          return (
            <div
              className="firstLine rline fullWidth top5"
              style={{ paddingLeft: '0' }}
            >
              <div className="flex">
                <div className="List Top" style={{ width: '100%' }}>
                  <div className="sepBottom">
                    <span className="riderIcon">
                      <span className="Name">{rider.name}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })

      return (
        <div className="top5">
          <span>Additional {marker.object.fieldTripRiderAdults.length}</span>
          {data}
        </div>
      )
    }
  }

  childRidersAddition = (marker, readOnly) => {
    let activeRuns = this.state.fieldTrip.data.find(
      (x) => x.id === this.state.activeTrip
    )
    let fieldTripRuns = activeRuns.fieldTrip.fieldTripRuns
    let activeRun = fieldTripRuns.find((x) => x.id === this.state.activeRun)
    if (!activeRun) {
      return
    }
    let point = activeRun.fieldTripPoints.find((x) => x.id === marker.object.id)

    let Additional =
      point &&
      point.fieldTripRiderAdults.map((rider, index) => {
        return (
          <div
            key={rider.id}
            className="firstLine rline fullWidth top5"
            style={{ paddingLeft: '0' }}
          >
            <div className="flex">
              <div className="List Top" style={{ width: '100%' }}>
                <div className="sepBottom">
                  <span className="riderIcon">
                    <span className="Name">
                      {rider.name}
                      <span
                        className="deleteIcon marginLeft12"
                        onClick={() =>
                          this.deleteRider(point.id, rider.id, true)
                        }
                      >
                        <span className="DeLine "></span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      })

    if (point && point.fieldTripRiderAdults.length > 0) {
      if (Additional) {
        return (
          <div className="top5">
            <span>Additional</span>
            {Additional}
          </div>
        )
      }
    }
  }

  childRiders = (marker, readOnly) => {
    let activeRuns = this.state.fieldTrip.data.find(
      (x) => x.id === this.state.activeTrip
    )
    let fieldTripRuns = activeRuns.fieldTrip.fieldTripRuns
    let activeRun = fieldTripRuns.find((x) => x.id === this.state.activeRun)
    if (!activeRun) {
      return
    }
    let point = activeRun.fieldTripPoints.find((x) => x.id === marker.object.id)
    let rider =
      point &&
      point.fieldTripRiders.map((rider, index) => {
        return (
          <div
            key={rider.id}
            className="firstLine rline fullWidth top5"
            style={{ paddingLeft: '0' }}
          >
            <div className="flex">
              <div className="List Top" style={{ width: '100%' }}>
                <div className="sepBottom">
                  <span className="riderIcon">
                    <span className="Name">
                      {rider.child.full_name}
                      <span
                        className="deleteIcon marginLeft12"
                        onClick={() =>
                          this.deleteRider(point.id, rider.id, false)
                        }
                      >
                        <span className="DeLine "></span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      })

    if (point && point.fieldTripRiders.length > 0) {
      if (rider) {
        return (
          <div className="top5">
            <span>Riders</span>
            {rider}
          </div>
        )
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.mapZoom) return true
    if (nextState.zoom) return true
    if (nextProps.activeRun && nextProps.activeTrip) {
      let fieldTripRuns = nextProps.fieldTrip.data.find(
        (x) => x.id === nextProps.activeTrip
      ).fieldTrip.fieldTripRuns
      let activeRun = fieldTripRuns.find((x) => x.id === nextProps.activeRun)
      let lenghtPoints = 0
      if (activeRun && activeRun.fieldTripPoints) {
        lenghtPoints = activeRun.fieldTripPoints.length
      } else {
        return true
      }

      if (activeRun.fieldTripPoints.length < 2) return true

      if (
        activeRun.hasOwnProperty('osrm') &&
        activeRun.osrm &&
        activeRun.osrm.hasOwnProperty('waypoints')
      ) {
        let lenghtOsrm = activeRun.osrm.waypoints.length

        if (lenghtPoints < 2 && lenghtOsrm < 2) {
          return false
        }
      } else {
        return false
      }
    }
    return true
  }

  componentWillReceiveProps(nextProps) {
    let { activeRun, activeTrip } = this.props

    if (this.props.activeTarget !== nextProps.activeTarget) {
      let b = this.getBounds()
      this.mapRef.current?.leafletElement?.fitBounds(b)
    }

    if (
      activeTrip !== nextProps.activeTrip ||
      this.state.activeRun !== nextProps.activeRun ||
      this.state.fieldTrip !== nextProps.fieldTrip
    ) {
      if (activeRun !== nextProps.activeRun) {
        let requestActive = nextProps.fieldTrip.data.find(
          (req) => req.id === nextProps.activeTrip
        )
        let ftActive = requestActive.fieldTrip.fieldTripRuns.find(
          (req) => req.id === nextProps.activeRun
        )

        this.setState({
          osrm: {},
        })

        if (
          ftActive &&
          ftActive.fieldTripPoints &&
          ftActive.fieldTripPoints.length >= 2
        ) {
          let ftActivePoints = [...ftActive.fieldTripPoints]
          let sortedPoints = []
          ftActivePoints.forEach((point) => {
            sortedPoints[point.position - 1] = point
          })

          sortedPoints = sortedPoints.filter(
            (value) => Object.keys(value).length !== 0
          )

          let requestOld = this.state.fieldTrip.data.find(
            (req) => req.id === nextProps.activeTrip
          )
          let ftActiveOld = requestOld.fieldTrip.fieldTripRuns.find(
            (req) => req.id === nextProps.activeRun
          )

          if (
            ftActive.osrm !== undefined &&
            ftActive.osrm === ftActiveOld.osrm
          ) {
            let markers = getRouteMarkersFT(ftActive)

            this.setState({
              osrm: ftActive.osrm,
              markers: markers,
            })
            this.props.osrm(ftActive.osrm)
          } else {
            this.props.dispatch(
              fieldTripActions.getOSRM(
                sortedPoints,
                nextProps.activeTrip,
                nextProps.activeRun
              )
            )
          }
        }
      }

      if (this.state.fieldTrip !== nextProps.fieldTrip) {
        let requestActive = nextProps.fieldTrip.data.find(
          (req) => req.id === nextProps.activeTrip
        )

        if (requestActive) {
          let ftActive = requestActive.fieldTrip.fieldTripRuns.find(
            (req) => req.id === nextProps.activeRun
          )

          if (ftActive) {
            let ftActivePoints = [...ftActive.fieldTripPoints]
            let sortedPoints = []
            ftActivePoints.forEach((point) => {
              sortedPoints[point.position - 1] = point
            })

            sortedPoints = sortedPoints.filter(
              (value) => Object.keys(value).length !== 0
            )

            if (ftActive.osrm) {
              let markers = getRouteMarkersFT(ftActive)
              this.setState({
                osrm: ftActive.osrm,
                markers: markers,
              })
              this.props.osrm(ftActive.osrm)
            } else if (sortedPoints.length >= 2) {
              let markers = getRouteMarkersFT(ftActive)
              this.setState({
                osrm: [],
                markers: markers,
              })
              this.props.dispatch(
                fieldTripActions.getOSRM(
                  sortedPoints,
                  nextProps.activeTrip,
                  nextProps.activeRun
                )
              )
            } else {
              let markers = getRouteMarkersFT(ftActive)
              this.setState({
                markers: markers,
                osrm: [],
              })
            }
          }
        }

        this.setState({
          fieldTrip: nextProps.fieldTrip,
        })
      }
    } else if (this.state.activeRun !== nextProps.activeRun) {
      this.setState({
        osrm: {},
      })
    }

    if (nextProps.depots && !_.isEmpty(nextProps.depots)) {
      let bounds = _.map(nextProps.depots, (depot) => {
        return [depot.address.lat, depot.address.lon]
      })
      if (_.isEmpty(bounds)) {
        bounds = [
          [90, 0],
          [90, 100],
        ]
      }
      this.setState({
        bounds: bounds,
        depots: nextProps.depots,
      })
    }

    if (this.state.mapZoom !== nextProps.mapZoom) {
      let zoom =
        this.mapRef?.current?.leafletElement?.getZoom() + nextProps.mapZoom[1]
      if (zoom > 18 || zoom < 0) return false
      this.setState({
        zoom: zoom,
        mapZoom: nextProps.mapZoom,
      })
    }

    this.setState({
      activeRun: nextProps.activeRun,
      activeTrip: nextProps.activeTrip,
    })
  }

  key = mapKey
  maptype = 'SATELLITE'

  arrowOptions = {
    repeat: true,
    offset: 3,
    attributes: {
      fill: 'white',
      'font-weight': 'normal',
      'font-size': '12',
    },
  }

  getBounds = () => {
    let osrm = this.state.osrm
    if (osrm && osrm.legs && osrm.legs.length > 0) {
      let newArr = []
      osrm.legs.forEach((leg, i, arr) => {
        if (arr.length === i + 1) {
          newArr.push([leg.end_point.lat, leg.end_point.lon])
        }
        newArr.push([leg.start_point.lat, leg.start_point.lon])
      })
      return newArr.concat(newArr.bounds)
    } else {
      let bounds = _.map(this.props.depots, (depot) => {
        return [depot.address.lat, depot.address.lon]
      })
      if (_.isEmpty(bounds)) {
        return [
          [24.664018, -123.461071],
          [46.088543, -56.280215],
        ]
      }
      return bounds
    }
  }

  gen = () => {
    let activeFT = this.state.activeFT
    if (activeFT && activeFT.fieldTrip && activeFT.fieldTrip.fieldTripRuns) {
      activeFT.fieldTrip.fieldTripRuns.forEach((x) => {
        let markers = getRouteMarkersFT(x)
        x.markers = markers
      })

      if (this.state.ftrs !== activeFT.fieldTrip.fieldTripRuns) {
        this.setState({
          ftrs: activeFT.fieldTrip.fieldTripRuns,
        })
      }
      return activeFT
    }
  }

  data = () => {
    let { depots } = this.props
    let generatedList = this.gen()

    let assignedPopupBind = assignedPopup.bind(this)
    return (
      <div>
        <Map bounds={this.getBounds()}>
          {generatedList &&
            generatedList.fieldTrip &&
            generatedList.fieldTrip.fieldTripRuns.map((x, i) => {
              if (x.osrm) {
                return (
                  <div key={i}>
                    <PolylineText
                      arrowOptions={this.arrowOptions}
                      pathOptions={directionsPathOptions(i)}
                      geometry={x.osrm.geometry}
                      name={x.name}
                    />
                  </div>
                )
              }
            })}

          {generatedList &&
            generatedList.fieldTrip &&
            generatedList.fieldTrip.fieldTripRuns.map((x, i) => {
              if (this.props.activeRun && x.id !== this.props.activeRun.id) {
                return
              }
              if (x.markers) {
                let arrayMarker = _.orderBy(
                  x.markers,
                  function (e) {
                    return e.object.position
                  },
                  ['desc']
                )

                return arrayMarker.map((marker) => {
                  marker.object.readOnly = true
                  marker.sequence = marker.object.position
                  marker.object.view = 'mapFtView'

                  return (
                    <Marker
                      key={marker.id}
                      position={marker.location}
                      icon={getIcon(marker.object.type, marker.sequence)}
                    >
                      {assignedPopupBind(marker)}
                    </Marker>
                  )
                })
              }
            })}

          {depots &&
            depots.map((depot) => {
              return (
                <Marker
                  key={depot.id}
                  onContextMenu={contextMenuOnDepot}
                  position={[depot.address.lat, depot.address.lon]}
                  icon={getIcon('busdepot', '')}
                />
              )
            })}

          {this.state.googleRoadLayer}
        </Map>
      </div>
    )
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showMap: true,
      })
    }, 1)
  }

  render() {
    let { osrm, markers, zoom } = this.state
    let assignedPopupBind = assignedPopup.bind(this)
    let generatedList = this.gen()
    let arrayMarker = _.orderBy(
      markers,
      function (e) {
        if (typeof e === 'object')
          return e.object && e.object.position && e.object.position
      },
      ['desc']
    )

    if (!this.state.showMap) return <div />
    return (
      <div>
        {/*<MapProvider>*/}
        <Map
          bounds={this.getBounds()}
          trackResize={true}
          zoomControl={false}
          zoom={zoom}
          ref={this.mapRef}
          whenReady={this.loadingMap}
        >
          <div>
            {osrm && osrm.geometry && (
              <PolylineText
                arrowOptions={this.arrowOptions}
                pathOptions={directionsPathOptions()}
                geometry={osrm.geometry}
              />
            )}
          </div>

          {arrayMarker.length &&
            arrayMarker.map((marker) => {
              if (marker.object) {
                if (marker) marker.sequence = marker.object.position
                return (
                  <Marker
                    key={marker.id}
                    position={marker.location}
                    icon={getIcon(marker.object.type, marker.sequence)}
                    onMouseOver={(e) => fnMouseOver(e)}
                    onMouseOut={(e) => fnMouseOut(e)}
                  >
                    {assignedPopupBind(marker)}
                  </Marker>
                )
              }
            })}

          {_.map(this.props.depots, (depot) => {
            return (
              <Marker
                key={depot.id}
                position={[depot.address.lat, depot.address.lon]}
                icon={getIcon('busdepot', '')}
              />
            )
          })}
          <ReactLeafletGoogleLayer
            ft={true}
            map={this.mapRef.current}
            maptype={this.props.maptype}
          />
        </Map>
        {/*</MapProvider>*/}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    depots: state.auth.depots,
    fieldTrip: state.fieldTripRequests,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { fieldTripActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapRunBuilder)
