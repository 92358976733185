import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import { Trans } from 'react-i18next'

import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '../../../../../../components/RepeatFunctions/timeWork'

import './index.less'
import StopChildAddress from '../../../../../Run/components/BuildRun/components/StopPoint/StopChildAddress'
import anchorAndTimeFN from '../../../../../Run/components/BuildRun/components/anchorAndTime'
import { sortRidersList } from '../../../../../../components/RepeatFunctions/filter'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

class SchoolPoint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
  }

  handleClick = (e, name) => {
    this.setState({
      show: !this.state.show,
      edit: true,
    })
    if (name === 'default') {
      this.setState({
        edit: !this.state.edit,
        show: !this.state.show,
      })
    }
  }

  componentDidMount() {
    if (this.props.companyOptions.expand_riders_in_view_runs) {
      this.setState({
        show: true,
        edit: true,
      })
    }
  }
  childList = () => {
    let { id, routePointChildren } = this.state

    if (routePointChildren === undefined) {
      return false
    }

    routePointChildren = sortRidersList(routePointChildren)

    let pickUpChild = routePointChildren.filter(
      (x) => x.childaddress.type === 1
    )
    let dropOffChild = routePointChildren.filter(
      (x) => x.childaddress.type === 2
    )

    return (
      <div>
        {routePointChildren && routePointChildren.length ? (
          <div className="SchoolAddress">
            <span className="riders">
              <Trans>Riders</Trans> {routePointChildren.length}
            </span>
            {this.state.show ? (
              <UpOutlined
                onClick={(e) => this.handleClick(e, 'Ediable')}
                style={{ marginLeft: '2px' }}
              />
            ) : (
              <DownOutlined
                onClick={(e) => this.handleClick(e, 'Ediable')}
                style={{ marginLeft: '2px' }}
              />
            )}
          </div>
        ) : null}

        {this.state.show && (
          <div className="PuckUpMain marginTop">
            <div className="pickUpBlock">
              {pickUpChild.length > 0 && (
                <div className="pickUp">
                  <Trans>Pick Up Riders</Trans>
                </div>
              )}

              {pickUpChild.map((address) => {
                let child = (this.props.childrenList || []).find(
                  (child) => child.id === address.childaddress.child_id
                )
                return (
                  <StopChildAddress
                    id={id}
                    child={child}
                    address={address}
                    readOnly={true}
                    delChildFromStop={this.delChildFromStop}
                  />
                )
              })}
            </div>

            <div
              className={`dropOffBlock ${
                pickUpChild.length > 0 ? `marginTop` : ''
              }`}
            >
              {dropOffChild.length > 0 && (
                <div className="dropOff">
                  <Trans>Drop Off Riders</Trans>
                </div>
              )}
              {dropOffChild.map((address) => {
                let child = (this.props.childrenList || []).find(
                  (child) => child.id === address.childaddress.child_id
                )

                return (
                  <StopChildAddress
                    id={id}
                    child={child}
                    readOnly={true}
                    address={address}
                    delChildFromStop={this.delChildFromStop}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    let { id, object, address } = this.state
    let position = this.state.routePointRoute.view_position
    let { isAnchor, schools, routeWaittime, pointOsrmLeg, route } = this.props

    let arriveTime = null
    let distance = null
    let duration = null

    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)

      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }

    return (
      <div key={id} className="schools">
        <span className="backgroundLeft" />
        {object && (
          <Row style={{ width: '100%' }} className="MarginBlock noMarginTop">
            <Col span={24} className="fullName">
              {position}. {object ? object.name : 'No have name'}
            </Col>
          </Row>
        )}

        <Row style={{ width: '100%' }} className="marginTopEight">
          <Col span={12} className={'dist'}>
            {distance && duration && (
              <div>
                <span>{distance}</span>
                <span>{duration}</span>
              </div>
            )}
          </Col>
          <Col span={24} className={'waitArr'}>
            {anchorAndTimeFN.bind(this)()}
          </Col>
        </Row>

        <Row style={{ width: '100%' }} className="Info">
          {this.props.first && (
            <Row style={{ width: '100%' }}>
              <Col span={24} className="text">
                <Trans>The Run Starts from here</Trans>
              </Col>
            </Row>
          )}

          <Row style={{ width: '100%' }} className="MarginBlock">
            {address && (
              <Col span={24}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="Address noIcon">
                    <Trans>Address</Trans>
                  </span>
                  {address && address.address}
                </div>
              </Col>
            )}
            <Col span={24}>
              <div className="firstLine childList MarginBlock">
                <div style={{ width: '100%' }}>{this.childList()}</div>
              </div>
            </Col>
          </Row>
          <Row style={{ width: '100%' }} className="MarginBlock" />
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    companyOptions: state.auth.companyOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { runActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolPoint)
